import invariant from 'tiny-invariant'
import { Currency } from '@pancakeswap/sdk'
import { validateAndParseAddress } from '../utils'
import { ChainId } from '../../config/constants/chainId'

export type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}
export type TokenRegister = {
  [key in ChainId]: Token
}

/**
 * Represents an ERC20 token with a unique address and some metadata.
 */
export class Token extends Currency {
  public readonly chainId: ChainId

  public readonly address: string

  public constructor(chainId: ChainId, address: string, decimals: number, symbol?: string, name?: string) {
    super(decimals, symbol, name)
    this.chainId = chainId
    this.address = validateAndParseAddress(address)
  }

  /**
   * Returns true if the two tokens are equivalent, i.e. have the same chainId and address.
   * @param other other token to compare
   */
  public equals(other: Token): boolean {
    // short circuit on reference equality
    if (this === other) {
      return true
    }
    return this.chainId === other.chainId && this.address === other.address
  }

  /**
   * Returns true if the address of this token sorts before the address of the other token
   * @param other other token to compare
   * @throws if the tokens have the same address
   * @throws if the tokens are on different chains
   */
  public sortsBefore(other: Token): boolean {
    invariant(this.chainId === other.chainId, 'CHAIN_IDS')
    invariant(this.address !== other.address, 'ADDRESSES')
    return this.address.toLowerCase() < other.address.toLowerCase()
  }
}

/**
 * Compares two currencies for equality
 */
export function currencyEquals(currencyA: Currency, currencyB: Currency): boolean {
  if (currencyA instanceof Token && currencyB instanceof Token) {
    return currencyA.equals(currencyB)
  }

  if (currencyA instanceof Token) {
    return false
  }

  if (currencyB instanceof Token) {
    return false
  }

  return currencyA === currencyB
}

export const WETH: TokenRegister = {
  [ChainId.UNDEFINED]: new Token(
    ChainId.ETHEREUM,
    '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    18,
    'WETH',
    'Wrapped Ether',
  ),
  [ChainId.ETHEREUM]: new Token(
    ChainId.ETHEREUM,
    '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    18,
    'WETH',
    'Wrapped Ether',
  ),
  [ChainId.RINKEBY]: new Token(
    ChainId.RINKEBY,
    '0xc778417e063141139fce010982780140aa0cd5ab',
    18,
    'WETH',
    'Wrapped Ether',
  ),
  [ChainId.BSC]: new Token(ChainId.BSC, '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c', 18, 'WBNB', 'Wrapped BNB'),
  [ChainId.BSCTEST]: new Token(
    ChainId.BSCTEST,
    '0xae13d989dac2f0debff460ac112a837c89baa7cd',
    18,
    'WBNB',
    'Wrapped BNB',
  ),
  [ChainId.POLYGON]: new Token(
    ChainId.POLYGON,
    '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    18,
    'WMATIC',
    'Wrapped MATIC',
  ),
  [ChainId.AVALANCHE]: new Token(
    ChainId.AVALANCHE,
    '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    18,
    'WAVAX',
    'Wrapped AVAX',
  ),
  [ChainId.AVALANCHETEST]: new Token(
    ChainId.AVALANCHETEST,
    '0xd00ae08403b9bbb9124bb305c09058e32c39a48c',
    18,
    'WAVAX',
    'Wrapped AVAX',
  ),
  [ChainId.FANTOM]: new Token(ChainId.FANTOM, '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83', 18, 'WFTM', 'Wrapped FTM'),
  // todo: change to working local tokens later
  [ChainId.LETH]: new Token(ChainId.LETH, '0x0000000000000000000000000000000000000000', 18, 'WETH', 'Wrapped ETH'),
  [ChainId.LBSC]: new Token(ChainId.LBSC, '0x0000000000000000000000000000000000000000', 18, 'WBNB', 'Wrapped BNB'),
}

export function symbolAddressMatch(symbolOrAddress: string, symbol?: string, address?: string) {
  if (!symbolOrAddress) return false

  if (symbol && symbolOrAddress.toLowerCase() === symbol.toLowerCase()) return true
  if (address && symbolOrAddress.toLowerCase() === address.toLowerCase()) return true

  return false
}

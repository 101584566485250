import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { Link } from 'react-router-dom'
import { Flex } from '@pancakeswap/uikit'
import { HamburgerIcon, HamburgerCloseIcon, LogoIcon as LogoWithText, LogoNoTextIcon } from '../icons'
import MenuButton from './MenuButton'

interface Props {
  isPushed: boolean
  isDark: boolean
  togglePush: () => void
  href: string
}

const blink = keyframes`
  0%,  100% { transform: scaleY(1); } 
  50% { transform:  scaleY(0.1); } 
`

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  .mobile-icon {
    width: 32px;
  }
  .desktop-icon {
    display: none;
    ${({ theme }) => theme.mediaQueries.nav} {
      display: block;
    }
  }
  .right-eye {
    animation-delay: 20ms;
  }
  &:hover {
    .left-eye,
    .right-eye {
      transform-origin: center 60%;
      animation-name: ${blink};
      animation-duration: 350ms;
      animation-iteration-count: 1;
    }
  }
`

const Logo: React.FC<Props> = ({ isPushed, togglePush, isDark, href }) => {
  const [isMinimized, setIsMinimized] = useState(window.innerWidth < 960)

  const innerLogo = (
    <>
      {isMinimized ? (
        <LogoNoTextIcon className="desktop-icon" isDark={isDark} />
      ) : (
        <LogoWithText className="desktop-icon" isDark={isDark} />
      )}
    </>
  )
  const updateMedia = () => {
    setIsMinimized(window.innerWidth < 960)
  }

  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })

  return (
    <Flex>
      <MenuButton aria-label="Toggle menu" onClick={togglePush} mr="4px">
        {isPushed ? (
          <HamburgerCloseIcon width="24px" color="textSubtle" />
        ) : (
          <HamburgerIcon width="24px" color="textSubtle" />
        )}
      </MenuButton>
      {window.innerWidth < 960 && window.innerWidth > 430 ? (
        <StyledLink as="a" href={href} aria-label="Defido Coin Bridge home page">
          {innerLogo}
        </StyledLink>
      ) : (
        <StyledLink as="a" href={href} aria-label="Defido Coin Bridge home page">
          {innerLogo}
        </StyledLink>
      )}
    </Flex>
  )
}

export default React.memo(Logo, (prev, next) => prev.isPushed === next.isPushed && prev.isDark === next.isDark)

import React from 'react'
import styled from 'styled-components'
import { Text, Link } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import Button from '../../../theme/components/Button/Button'

const ButtonText = styled(Text)`
  display: block;
`

const StyledButton = styled(Button)`
  background-color: white;
`

const StyledLink = styled(Link)`
  display: flex;
  justify-content: flex-end;

  &:hover {
    text-decoration: none;
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    flex: 1;
  }
`

const ManageLiquidityButton = () => {
  const { t } = useTranslation()
  return (
    <StyledLink href="/bridge/tokens">
      <StyledButton px={['0px', null, null, null, '0px']} variant="primary">
        {/* <ButtonText color="secondary" bold fontSize="16px">
          {t('Refresh page')}
        </ButtonText> */}
      </StyledButton>
    </StyledLink>
  )
}

export default ManageLiquidityButton

import getTokens from '../tokens'
import getLPTokens from '../tokensLP'
import { FarmConfig } from '../types'

export default function getFarms(): FarmConfig[] {
  const lpTokens = getLPTokens()
  const tokens = getTokens()

  return [
    // {
    //   farmId: 0,
    //   lpSymbol: 'TXL-LP',
    //   lpToken: lpTokens.txl,
    //   token: tokens.txl,
    // },
    {
      farmId: 1,
      lpSymbol: 'USDC-LP',
      lpToken: lpTokens.usdc,
      token: tokens.usdc,
    },
    // {
    //   farmId: 2,
    //   lpSymbol: 'USDT-LP',
    //   lpToken: lpTokens.usdt,
    //   token: tokens.usdt,
    // },
  ]
}

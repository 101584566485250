import { ChainId } from './chainId'

const NETWORK_URLS: { [chainId in ChainId]: string } = {
  [ChainId.UNDEFINED]: '',
  [ChainId.ETHEREUM]: 'https://eth-mainnet.alchemyapi.io/v2/mnr_i00kLR6YN5W_BOML5TtSH_Yuht2x',
  [ChainId.RINKEBY]: 'https://rinkeby-light.eth.linkpool.io/',
  [ChainId.BSC]: 'https://bsc-dataseed1.defibit.io',
  [ChainId.BSCTEST]: 'https://data-seed-prebsc-1-s1.binance.org:8545',
  [ChainId.LETH]: 'http://127.0.0.1:6661',
  [ChainId.LBSC]: 'http://127.0.0.1:6662',
  [ChainId.POLYGON]: 'https://polygon-rpc.com/',
  [ChainId.AVALANCHE]: 'https://api.avax.network/ext/bc/C/rpc',
  [ChainId.AVALANCHETEST]: 'https://api.avax-test.network/ext/bc/C/rpc',
  [ChainId.FANTOM]: 'https://rpc.ftm.tools/',
}

export default NETWORK_URLS

const networksProduction = [
  {
    name: 'Binance Smart Chain',
    chainId: ChainId.BSC,
  },
  {
    name: 'Ethereum',
    chainId: ChainId.ETHEREUM,
  },
  {
    name: 'Polygon (Matic)',
    chainId: ChainId.POLYGON,
  },
  {
    name: 'Avalanche',
    chainId: ChainId.AVALANCHE,
  },
  {
    name: 'Fantom',
    chainId: ChainId.FANTOM,
  },
]

const networksTestnet = [
  {
    name: 'BSC Testnet',
    chainId: ChainId.BSCTEST,
  },
  {
    name: 'Rinkeby',
    chainId: ChainId.RINKEBY,
  },
]

const networksLocal = [
  ...networksProduction,
  ...networksTestnet,
  {
    name: 'Local BSC',
    chainId: ChainId.LBSC,
  },
  {
    name: 'Local ETH',
    chainId: ChainId.LETH,
  },
]

export const networks =
  process.env.REACT_APP_ENV === 'prod'
    ? networksProduction
    : process.env.REACT_APP_ENV === 'test'
    ? networksTestnet
    : networksLocal

export const getNetworkNameByChainId = (chainId: ChainId) => {
  switch (chainId) {
    case ChainId.ETHEREUM:
    case ChainId.RINKEBY:
    case ChainId.LETH:
      return 'ethereum'
    case ChainId.BSC:
    case ChainId.BSCTEST:
    case ChainId.LBSC:
      return 'bsc'
    case ChainId.POLYGON:
      return 'polygon'
    case ChainId.AVALANCHE:
    case ChainId.AVALANCHETEST:
      return 'avalanche'
    case ChainId.FANTOM:
      return 'fantom'
    default:
      return null
  }
}

export function validChainId(chainId: ChainId) {
  return networks.find((n) => n.chainId === chainId)
}

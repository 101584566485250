import React from 'react'
import styled from 'styled-components'
import { Text, ArrowForwardIcon, OpenNewIcon } from '@pancakeswap/uikit'

import { useTranslation } from 'contexts/Localization'
import { NETWORK_ICON } from 'config/constants/networkIcons'
import { ArrowWrapper } from 'components/styleds'
import Column from 'components/Layout/Column'
import { Row, RowBetween } from 'components/Layout/Row'
import Button from 'theme/components/Button/Button'
import { CurrencyLogo } from 'components/Logo'
import { NetworkIconImg } from 'views/BridgeERC20/components/ClaimList'
import { Token } from 'sdk/entities/token'
import { TokenAmount } from 'sdk/entities/tokenAmount'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { getBscScanLink } from 'utils'
import { getWaitConfirmations } from '../../state/bridgeERC20/hooks'

const ListLabel = styled.div`
  padding: 0 1rem 0 0;
  margin-bottom: 0.5rem;

  ${({ theme }) => theme.mediaQueries.xs} {
    width: 125px;
    margin-bottom: 0;
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    width: 200px;
  }
`

const IconWrapper = styled.div`
  margin-right: 0.5rem;
`

const PulseAnimationWrapper = styled.div<{ next?: boolean }>`
  animation: ${({ next }) => (next ? 'pulse 2s infinite' : 'none')};
  border-radius: 16px;
  box-shadow: 0 0 0 0 rgba(52, 172, 224, 1);
  :hover {
    -webkit-animation: none;
  }
  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
    }
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(52, 172, 224, 0);
    }
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
    }
  }
`

const Circle = styled.div<{ confirmed?: boolean; open?: boolean; next?: boolean }>`
  min-width: 20px;
  min-height: 20px;
  background-color: ${({ theme, confirmed }) => (confirmed ? theme.colors.success : theme.colors.backgroundDisabled)};
  border-radius: 50%;
  border-width: 1px;
  border-style: dashed;
  border-color: ${({ theme, open, next }) => (next ? theme.colors.primary : open ? 'darkgray' : 'transparent')};
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 8px;
  font-size: 12px;
  animation: ${({ next }) => (next ? 'rotation 10s linear infinite' : 'none')};

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`

const ClickableIcon = styled.a`
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  cursor: pointer;
  margin-left: 0.2rem;
`

function ExtIconLink({ href }) {
  return (
    <ClickableIcon href={href} >
      <OpenNewIcon width={16} color="gray" />
    </ClickableIcon>
  )
}

type CheckItemProps = {
  children?: any
  checked?: boolean
  next?: boolean
  checkboxWidth?: string
  iconMarginBottom?: string | number
}

export function CheckItem({ children, checked, next, checkboxWidth, iconMarginBottom }: CheckItemProps) {
  return (
    <RowBetween width={checkboxWidth} mb={iconMarginBottom || '0.5rem'}>
      <div>{children}</div>
      <div>
        {!checked && <Circle open next={next} />}
        {checked && <Circle confirmed>✓</Circle>}
      </div>
    </RowBetween>
  )
}

type TextItemProps = {
  children: any
  strike?: boolean
}

function TextItem({ children, strike }: TextItemProps) {
  return (
    <Text fontSize="12px" style={{ textDecoration: strike ? 'line-through' : 'none' }}>
      {children}
    </Text>
  )
}

function getActiveNextIndex({ deposited, confirmationCheck, depositAccepted, signatures, claimed, onTargetNetwork }) {
  if (!deposited) return 0
  if (!depositAccepted) return 1
  if (!confirmationCheck) return 2
  if (!onTargetNetwork) return 3
  if (!signatures) return 4
  if (!claimed) return 5
  return 6
}

type ActiveBridgeInfoProps = {
  amount?: TokenAmount
  fee?: TokenAmount
  token?: Token
  sourceChainId?: number
  targetChainId?: number
  deposited?: boolean
  waitingDepositConfirmations?: boolean
  confirmations?: number
  canSwitchNetwork?: boolean
  depositAccepted?: boolean
  onSwitchNetwork?: () => any
  signatures?: boolean
  claimed?: boolean
  success?: boolean
  depositTxHash?: string
  releaseTxHash?: string
}

export default function ActiveBridgeInfo({
  amount,
  token,
  fee,
  sourceChainId,
  targetChainId,
  deposited,
  depositAccepted,
  confirmations,
  waitingDepositConfirmations,
  canSwitchNetwork,
  onSwitchNetwork,
  signatures,
  claimed,
  success,
  depositTxHash,
  releaseTxHash,
}: ActiveBridgeInfoProps) {
  const { t } = useTranslation()
  const { chainId } = useActiveWeb3React()
  const onTargetNetwork = chainId === targetChainId
  const confirmationCheck = onTargetNetwork || !waitingDepositConfirmations
  const nextCheckItemIndex = getActiveNextIndex({
    deposited,
    depositAccepted,
    confirmationCheck,
    signatures,
    claimed,
    onTargetNetwork,
  })

  const WAIT_CONFIRMATIONS = getWaitConfirmations(sourceChainId)

  return (
    <>
      <RowBetween align="top" mt="1rem">
        <div>
          <Row mt="0.5rem">
            {token && (
              <IconWrapper>
                <CurrencyLogo currency={token} />
              </IconWrapper>
            )}
            <div>
              <div>
                {amount?.toSignificant(6)} {token?.symbol}
              </div>
              <div>
                {fee && (
                  <Text fontSize="12px">
                    Fee: {fee.toSignificant(6)} {token?.symbol}
                  </Text>
                )}
              </div>
            </div>
          </Row>
        </div>
        <div>
          <Row>
            <div>{sourceChainId && <NetworkIconImg src={NETWORK_ICON[sourceChainId]} />}</div>
            <div>
              <ArrowWrapper clickable={false}>
                <ArrowForwardIcon width="16px" color="primary" />
              </ArrowWrapper>
            </div>
            <div>{targetChainId && <NetworkIconImg src={NETWORK_ICON[targetChainId]} />}</div>
          </Row>
          <div>
            <Text textAlign="center" fontSize="12px">
              ETA 2-5 min.
            </Text>
          </div>
        </div>
      </RowBetween>
      <Column style={{ marginTop: '1rem' }}>
        <div>
          <Row align="top" mobileColumn>
            <ListLabel>
              <Text fontSize="14px" bold>
                {t('Deposit')}
              </Text>
            </ListLabel>
            <div style={{ flex: 1 }}>
              <CheckItem checked={deposited} next={nextCheckItemIndex === 0}>
                <TextItem>
                  {t('Sign deposit TX')}
                  {depositTxHash && <ExtIconLink href={getBscScanLink(depositTxHash, 'transaction', sourceChainId)} />}
                </TextItem>
              </CheckItem>
              <CheckItem checked={depositAccepted} next={nextCheckItemIndex === 1}>
                <TextItem>{t('Deposit TX accepted')}</TextItem>
              </CheckItem>
              <CheckItem checked={confirmationCheck} next={nextCheckItemIndex === 2}>
                <TextItem>
                  {t('Confirmations')}{' '}
                  {!waitingDepositConfirmations
                    ? ''
                    : `${confirmations > 0 ? confirmations : 0} / ${WAIT_CONFIRMATIONS}`}
                </TextItem>
              </CheckItem>
            </div>
          </Row>
        </div>
        <div>
          <Row align="top" mt="1rem" mobileColumn>
            <ListLabel>
              <Text fontSize="14px" bold>
                {t('Cross Chain')}
              </Text>
            </ListLabel>
            <div style={{ flex: 1 }}>
              {!onTargetNetwork && (
                <CheckItem next={nextCheckItemIndex === 3}>
                  <PulseAnimationWrapper next={nextCheckItemIndex === 3}>
                    <Button scale="xs" variant="secondary" disabled={!canSwitchNetwork} onClick={onSwitchNetwork}>
                      {t('Switch network')}
                    </Button>
                  </PulseAnimationWrapper>
                </CheckItem>
              )}
              {onTargetNetwork && (
                <CheckItem checked>
                  <Text fontSize="12px">{t('Switch network')}</Text>
                </CheckItem>
              )}
              <CheckItem checked={signatures} next={nextCheckItemIndex === 4}>
                <Text fontSize="12px">{t('Get signatures')}</Text>
              </CheckItem>
            </div>
          </Row>
        </div>
        <div>
          <Row align="top" mt="1rem" mobileColumn>
            <ListLabel>
              <Text fontSize="14px" bold>
                {t('Claim')}
              </Text>
            </ListLabel>
            <div style={{ flex: 1 }}>
              <CheckItem checked={claimed} next={nextCheckItemIndex === 5}>
                <Text fontSize="12px">
                  {t('Sign claim TX')}
                  {releaseTxHash && <ExtIconLink href={getBscScanLink(releaseTxHash, 'transaction', targetChainId)} />}
                </Text>
              </CheckItem>
              <CheckItem checked={success} next={nextCheckItemIndex === 6}>
                <Text fontSize="12px">{t('Bridging successful')}</Text>
              </CheckItem>
            </div>
          </Row>
        </div>
      </Column>
    </>
  )
}

import { ChainId } from './chainId'
import farmsAvalanche from './avalanche/farms'
import farmsBsc from './bsc/farms'
import farmsBscTest from './bsctest/farms'
import farmsDevEnv from './devenv/farms'
import farmsEthereum from './ethereum/farms'
import farmsFantom from './fantom/farms'
import farmsPolygon from './polygon/farms'
import farmsRinkeby from './rinkeby/farms'

const getFarms = (chainId: ChainId) => {
  switch (chainId) {
    // Dev Environment
    case ChainId.LETH:
    case ChainId.LBSC:
      return farmsDevEnv()

    // Testnet
    case ChainId.RINKEBY:
      return farmsRinkeby()
    case ChainId.BSCTEST:
      return farmsBscTest()

    // Production
    case ChainId.ETHEREUM:
      return farmsEthereum()
    case ChainId.BSC:
      return farmsBsc()
    case ChainId.POLYGON:
      return farmsPolygon()
    case ChainId.AVALANCHE:
      return farmsAvalanche()
    case ChainId.FANTOM:
      return farmsFantom()

    default:
      return farmsEthereum()
  }
}

export default getFarms

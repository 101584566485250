import { PoolConfig } from '../types'
import { ChainId } from '../chainId'
import getTokens, { getTokensList } from '../tokens'
import { asyncExtendedPools } from '../asyncExtendedPools'

const contractAddress = {
  [ChainId.RINKEBY]: '0xa1766c67dE869771e9693a1Ad0fBA9b60Cd027CE',
  [ChainId.BSCTEST]: '0x6BfB63f59e270757E2Df23b8b66E39Ab374d8e8a',
  [ChainId.LETH]: '0x92417136F219bf18f610AaC849F69c618bD2d381',
  [ChainId.LBSC]: '0x92417136F219bf18f610AaC849F69c618bD2d381',
}

let pools

function initialPools() {
  const tokens = getTokens()

  pools = [
    {
      rewardPoolId: 2,
      stakingToken: tokens.bridge,
      earningToken: tokens.usdc,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '0.01261',
      isFinished: false,
    },
    // {
    //   rewardPoolId: 1,
    //   stakingToken: tokens.bridge,
    //   earningToken: tokens.usdt,
    //   contractAddress,
    //   harvest: true,
    //   sortOrder: 999,
    //   tokenPerBlock: '0.01261',
    //   isFinished: false,
    // },
    // {
    //   rewardPoolId: 0,
    //   stakingToken: tokens.bridge,
    //   earningToken: tokens.txl,
    //   contractAddress,
    //   harvest: true,
    //   sortOrder: 999,
    //   tokenPerBlock: '0.01261',
    //   isFinished: false,
    // },
    // {
    //   rewardPoolId: 3,
    //   stakingToken: tokens.bridge,
    //   earningToken: tokens.link,
    //   contractAddress,
    //   harvest: true,
    //   sortOrder: 999,
    //   tokenPerBlock: '0.01261',
    //   isFinished: false,
    // },
  ]
}

export default function getPools(): PoolConfig[] {
  if (!pools) {
    initialPools()
  }

  return pools
}

asyncExtendedPools(
  'reward',
  'rewardPoolId',
  ChainId.BSCTEST,
  contractAddress,
  getPools,
  getTokensList,
  getTokensList,
  (pool) => pools.push(pool),
).catch((err) => console.error('Dynamic pools', err))

import React from 'react'
import styled from 'styled-components'
import { Text, Skeleton } from '@pancakeswap/uikit'
import { useTranslation } from '../../../../contexts/Localization'
import BridgeIcon from '../icons/BridgeIcon'
import { getSwapToBridgeTokenDexLink, getSwapToBridgeTokenDexLinkBase } from '../../../../utils'
import useActiveWeb3React from '../../../../hooks/useActiveWeb3React'
import { usePriceBridgeTokenUsd } from '../../../../state/farms/hooks'

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 0 4px 8px;
`
// Buy buttons for bridge token
const BuyButton = styled.a`
  -webkit-box-align: center;
  align-items: center;
  border: 0px;
  border-radius: 16px;
  box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: 14px;
  font-weight: 600;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  opacity: 1;
  outline: 0px;
  :hover {
    opacity: 0.65;
  }
  transition: background-color 0.2s, opacity 0.2s;
  height: 25px;
  padding: 0px 14px;
  background-color: #ffff;
  color: #000;
  text-decoration: none;
`

const PriceLink = styled.a`
  display: flex;
  align-items: center;
  svg {
    transition: transform 0.3s;
  }
  :hover {
    svg {
      transform: scale(1.2);
    }
  }
`

const BuyBridgePriceButton: React.FC = () => {
  const { t } = useTranslation()
  const { chainId } = useActiveWeb3React()
  const dexSwapLink = getSwapToBridgeTokenDexLink(chainId)
  const dexSwapLinkBase = getSwapToBridgeTokenDexLinkBase(chainId)
  // const bridgePriceUsd = usePriceBridgeTokenUsd()

  return (
    <Container>
      {/* <PriceLink href={dexSwapLink} target="_blank">
        <BridgeIcon width="24px" mr="8px" style={{ border: '1px solid grey', borderRadius: '50%' }} />
        {bridgePriceUsd.toFixed(3) && !bridgePriceUsd.isNaN() ? (
          <Text color="textSubtle" bold small>
            {`$${bridgePriceUsd.toFixed(3)}`}
          </Text>
        ) : (
          <Skeleton width={45} height={24} />
        )}
      </PriceLink> */}
      <BuyButton href={dexSwapLinkBase} target="_blank">
        {t('Buy BASE')}
      </BuyButton>
      <BuyButton href={dexSwapLink} target="_blank">
        {t('Buy BRIDGE')}
      </BuyButton>
    </Container>
  )
}

export default React.memo(BuyBridgePriceButton)

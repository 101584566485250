import React, { useContext } from 'react'
import { Currency, CurrencyAmount, JSBI } from '@pancakeswap/sdk'
import { Text } from '@pancakeswap/uikit'
import { AutoColumn } from 'components/Layout/Column'
import { AutoRow, RowBetween, RowFixed } from 'components/Layout/Row'
import { CurrencyLogo } from 'components/Logo'
import { ArrowDown } from 'react-feather'
import { ThemeContext } from 'styled-components'
import { isAddress, shortenAddress } from 'utils'
import { TruncatedText } from 'components/styleds'
import { TokenAmount } from 'sdk/entities/tokenAmount'
import CircleLoader from 'components/Loader/CircleLoader'

export default function BridgeModalHeader({
  amount,
  currency,
  recipient,
  fees,
  isWithdrawalToOtherNetwork,
}: {
  amount: CurrencyAmount
  currency: Currency
  recipient: string | null
  fees: TokenAmount | undefined
  isWithdrawalToOtherNetwork: boolean
}) {
  const theme = useContext(ThemeContext)
  const amountAfterFees =
    fees === undefined ? undefined : new TokenAmount(fees.token, JSBI.subtract(amount.raw, fees.raw)).toFixed(6)

  return (
    <AutoColumn gap="md" style={{ marginTop: '20px', marginBottom: '20px' }}>
      <RowBetween align="flex-end">
        <RowFixed gap="0px">
          <CurrencyLogo currency={currency} size="24px" style={{ marginRight: '12px' }} />
          <TruncatedText fontSize="24" fontWeight={500} color={theme.colors.primary}>
            {isWithdrawalToOtherNetwork ? amountAfterFees : amount.toSignificant(6)}
          </TruncatedText>
        </RowFixed>
        <RowFixed gap="0px">
          <Text fontSize="24" fontWeight={500} style={{ marginLeft: '10px' }}>
            {currency.symbol}
          </Text>
        </RowFixed>
      </RowBetween>
      <RowBetween>
        <AutoRow justify="center">
          <ArrowDown size="16" color={theme.colors.text} style={{ marginLeft: '4px', minWidth: '16px' }} />
        </AutoRow>
      </RowBetween>
      {recipient !== null ? (
        <AutoColumn justify="flex-start" gap="sm" style={{ padding: '12px 0 0 0px' }}>
          {isWithdrawalToOtherNetwork ? (
            <div>
              {/* fees are already calculated at this stage */}
              You can claim {amountAfterFees === undefined ? <CircleLoader /> : amountAfterFees} {currency.symbol} from{' '}
              <Text mt="12px" title={recipient} bold>
                <b>{isAddress(recipient) ? shortenAddress(recipient) : recipient}</b>
              </Text>
            </div>
          ) : (
            <div>
              After fees, you can claim {amountAfterFees === undefined ? <CircleLoader /> : amountAfterFees}{' '}
              {currency.symbol} from{' '}
              <Text mt="12px" title={recipient} bold>
                <b>{isAddress(recipient) ? shortenAddress(recipient) : recipient}</b>
              </Text>
            </div>
          )}
        </AutoColumn>
      ) : null}
    </AutoColumn>
  )
}

import React from 'react'
import styled from 'styled-components'
import { Box } from '@pancakeswap/uikit'
import Container from '../Layout/Container'
// import FooterBackground from '../../views/Home/components/FooterBackground.svg'

const BgWrapper = styled.div`
  overflow: hidden;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background-position: center;
  background-size: 200%;
  height: 200px;
  @media only screen and (min-width: 430px) {
    background-size: 200%;
    height: 600px;
  }
`

const Outer = styled(Box)<{ background?: string }>`
  background: linear-gradient(45deg, #7B3F00 0%, rgba(22, 160, 133) 100%, transparent);
`

const Inner = styled(Container)`
  padding-top: 32px;
  padding-bottom: 32px;
  z-index: 1;
  position: relative;
  @media only screen and (min-width: 430px) {
    background-size: 200%;
    height: 200px;
  }
`

const PageHeader: React.FC<{ background?: string }> = ({ background, children, ...props }) => (
  <Outer background={background} {...props}>
    <BgWrapper />
    <Inner>{children}</Inner>
  </Outer>
)

export default PageHeader

import { useMemo } from 'react'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import {
  getErc20Contract,
  getStakingPoolsContract,
  getErc721Contract,
  getRewardPoolsContract,
  getBridgeChefContract,
} from 'utils/contractHelpers'
import { getMulticallAddress } from 'utils/addressHelpers'

// Imports below migrated from Exchange useContract.ts
// import { Contract } from '@ethersproject/contracts'
import { Contract } from 'ethers'
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import ENS_PUBLIC_RESOLVER_ABI from '../config/abi/ens-public-resolver.json'
import ENS_ABI from '../config/abi/ens-registrar.json'
import { ERC20_BYTES32_ABI } from '../config/abi/erc20'
import ERC20_ABI from '../config/abi/erc20.json'
import WETH_ABI from '../config/abi/weth.json'
import BRIDGE_V4_ABI from '../config/abi/BridgeV4.json'
import BRIDGEV2BUYBACKANDBURN_ABI from '../config/abi/BridgeV2BuyBackAndBurn.json'
// import BRIDGE_ERC20_ABI from '../config/abi/BridgeV2ERC20V1.json'
// import BRIDGE_ERC721_ABI from '../config/abi/BridgeV2ERC721V1.json'
import multiCallAbi from '../config/abi/Multicall.json'
import { getContract } from '../utils'
import { WETH } from '../sdk/entities/token'
import { ChainId } from '../config/constants/chainId'

/**
 * Helper hooks to get specific contracts (by ABI)
 */

export const useERC20 = (address: string) => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getErc20Contract(address, library.getSigner()), [address, library])
}

/**
 * @see https://docs.openzeppelin.com/contracts/3.x/api/token/erc721
 */
export const useERC721 = (address: string) => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getErc721Contract(address, library.getSigner()), [address, library])
}

// Code below migrated from Exchange useContract.ts

// returns null on errors
function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useBBAndBurnContract(bbAndBurnAddress?: string): Contract | null {
  return useContract(bbAndBurnAddress, BRIDGEV2BUYBACKANDBURN_ABI)
}
/*
export function useBridgeERC20Contract(bridgeAddress?: string): Contract | null {
  return useContract(bridgeAddress, BRIDGE_ERC20_ABI)
}
 */

export function useBridgeContract(bridgeAddress?: string): Contract | null {
  return useContract(bridgeAddress, BRIDGE_V4_ABI)
}

export function useWETHContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? WETH[chainId].address : undefined, WETH_ABI, withSignerIfPossible)
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  let address: string | undefined
  if (chainId) {
    // eslint-disable-next-line default-case
    switch (chainId) {
      case ChainId.ETHEREUM:
      case ChainId.TESTNET:
        address = '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'
        break
    }
  }
  return useContract(address, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(pairAddress, IUniswapV2PairABI, withSignerIfPossible)
}

export function useMulticallContract(chainId: ChainId): Contract | null {
  return useContract(getMulticallAddress(chainId), multiCallAbi, false)
}

export const useStakingPoolsContract = (id) => {
  const { chainId, library } = useActiveWeb3React()
  return useMemo(() => getStakingPoolsContract(id, chainId, library.getSigner()), [id, chainId, library])
}

export const useRewardPoolsContract = (id) => {
  const { chainId, library } = useActiveWeb3React()
  return useMemo(() => getRewardPoolsContract(id, chainId, library.getSigner()), [id, chainId, library])
}

export const useBridgeChef = () => {
  const { chainId, library } = useActiveWeb3React()
  return useMemo(() => getBridgeChefContract(chainId, library.getSigner()), [library, chainId])
}

import { ChainId } from 'config/constants/chainId'
import getAddresses from 'utils/getAddresses'
import { Address } from 'config/constants/types'

export const getAddress = (address: Address, chainId: ChainId): string => {
  return address[chainId]
}
export const getBridgeErc20Address = (chainId: ChainId) => {
  return getAddresses(chainId).BridgeV2ERC20
}
export const getBridgeChefAddress = (chainId: ChainId) => {
  return getAddresses(chainId).BridgeV2BridgeChef
}
export const getMulticallAddress = (chainId?: ChainId) => {
  return getAddresses(chainId).Multicall
}

import BigNumber from 'bignumber.js/bignumber'
import { BIG_TEN } from 'utils/bigNumber'
import { ChainId } from './constants/chainId'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export const BSC_BLOCK_TIME = 3

export const BASE_EXPLORER_SCAN_URLS = {
  [ChainId.ETHEREUM]: 'https://etherscan.io',
  [ChainId.BSC]: 'https://bscscan.com',
  [ChainId.POLYGON]: 'https://polygonscan.com/',
  [ChainId.AVALANCHE]: 'https://snowtrace.io/',
  [ChainId.FANTOM]: 'https://ftmscan.com/',
  [ChainId.RINKEBY]: 'https://rinkeby.etherscan.io',
  [ChainId.BSCTEST]: 'https://testnet.bscscan.com',
  [ChainId.LBSC]: '',
  [ChainId.LETH]: '',
}

export const BLOCK_TIME = {
  [ChainId.ETHEREUM]: new BigNumber(13.25),
  [ChainId.BSC]: new BigNumber(3),
  [ChainId.POLYGON]: new BigNumber(2.25),
  [ChainId.AVALANCHE]: new BigNumber(2),
  [ChainId.FANTOM]: new BigNumber(0.9), // https://ftmscan.com/chart/blocktime
  [ChainId.LBSC]: new BigNumber(10), // there are no real block times locally but instead a block is only created when a tx is made
  [ChainId.LETH]: new BigNumber(10), // same
  [ChainId.RINKEBY]: new BigNumber(15),
  [ChainId.BSCTEST]: new BigNumber(15),
}

export const BRIDGE_PER_BLOCK = {
  [ChainId.ETHEREUM]: new BigNumber(4.65),
  [ChainId.BSC]: new BigNumber(2.01),
  [ChainId.POLYGON]: new BigNumber(1.33),
  [ChainId.AVALANCHE]: new BigNumber(0.271),
  [ChainId.FANTOM]: new BigNumber(0.115),
  [ChainId.LBSC]: new BigNumber(100),
  [ChainId.LETH]: new BigNumber(100),
  [ChainId.RINKEBY]: new BigNumber(100),
  [ChainId.BSCTEST]: new BigNumber(100),
}

export const REQUIRED_BLOCK_CONFIRMATIONS = {
  [ChainId.ETHEREUM]: 32,
  [ChainId.BSC]: 32,
  [ChainId.POLYGON]: 130,
  [ChainId.AVALANCHE]: 12,
  [ChainId.FANTOM]: 12,
  [ChainId.LBSC]: 12,
  [ChainId.LETH]: 12,
  [ChainId.RINKEBY]: 32,
  [ChainId.BSCTEST]: 32,
}

export const getBlocksPerYear = (chainId: ChainId) => {
  return new BigNumber((60 / BLOCK_TIME[chainId]) * 60 * 24 * 365)
}

export const getBridgePerYear = (chainId: ChainId) => {
  return BRIDGE_PER_BLOCK[chainId].times(getBlocksPerYear(chainId))
}

export const BASE_URL = process.env.REACT_APP_BASE_URI || 'https://crosschainbridge.org'
export const BASE_ADD_LIQUIDITY_URL = `${BASE_URL}/liquidity/add`
export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18)

export const DYNAMIC_TOKENS_URL = process.env.REACT_APP_DYNAMIC_TOKENS_URL || `http://localhost:4000`

import { useMemo } from 'react'
import JSBI from 'jsbi'
import { useAsync } from 'react-async-hook'
import { Contract, getDefaultProvider } from 'ethers'
import { CurrencyAmount } from '@pancakeswap/sdk'
import { TokenAmount } from 'sdk/entities/tokenAmount'
import { Token } from 'sdk/entities/token'
import { ChainId } from '../../config/constants/chainId'
import networkUrls from '../../config/constants/networks'
import getAddresses from '../../utils/getAddresses'
import CROSSCHAIN_BRIDGE_ERC20V1_ABI from '../../config/abi/CrossChainBridgeERC20V1.json'

export function useBridgingFees(
  amount?: CurrencyAmount,
  tokenAddress?: string,
  targetChainId?: ChainId,
): TokenAmount | undefined {
  // setup an RPC provider for the target chain
  const targetNetworkProvider = useMemo(() => {
    if (!targetChainId) {
      return undefined
    }
    return getDefaultProvider(networkUrls[targetChainId])
  }, [targetChainId])

  // build up the contract for the target chain
  const contract = useMemo(() => {
    return new Contract(getAddresses(targetChainId).BridgeV2ERC20, CROSSCHAIN_BRIDGE_ERC20V1_ABI, targetNetworkProvider)
  }, [targetNetworkProvider, targetChainId])

  // fetch the default fee
  const { result: defaultFee, loading: defaultFeeLoading } = useAsync(async () => {
    return contract.callStatic.defaultBridgeFee()
  }, [targetNetworkProvider])

  // fetch the custom token fee
  const { result: tokenFee, loading: tokenFeeLoading } = useAsync(async () => {
    return contract.callStatic.bridgeFees(tokenAddress)
  }, [tokenAddress, targetNetworkProvider])

  const defaultBridgeFee = useMemo(() => {
    if (defaultFeeLoading) return undefined
    if (defaultFee) {
      return JSBI.BigInt(defaultFee)
    }

    return JSBI.BigInt(0)
  }, [defaultFee, defaultFeeLoading])

  const bridgeFee = useMemo(() => {
    if (tokenFeeLoading) return undefined
    if (tokenFee) {
      return JSBI.BigInt(tokenFee)
    }

    return JSBI.BigInt(0)
  }, [tokenFee, tokenFeeLoading])

  return useMemo(() => {
    // still loading
    if (bridgeFee === undefined || defaultBridgeFee === undefined) return undefined

    // check which fee is above zero, prio the bridge fee, default fee is last
    const ppm =
      bridgeFee && JSBI.GT(bridgeFee, 0)
        ? bridgeFee
        : defaultBridgeFee && JSBI.GT(defaultBridgeFee, 0)
        ? defaultBridgeFee
        : JSBI.BigInt(0)

    if (!ppm || !amount) return undefined

    const tokenAmount = JSBI.BigInt(amount.raw || 0)
    const relativeFee = JSBI.divide(JSBI.multiply(tokenAmount, ppm), JSBI.BigInt(1000000))

    return new TokenAmount(amount.currency as Token, relativeFee)
  }, [defaultBridgeFee, bridgeFee, amount])
}

import { MenuEntry } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: t('BridgeERC20'),
    icon: 'TradeIcon',
    href: '/bridge/tokens',
  },
  // {
  //   label: t('BridgeNFTs'),
  //   icon: 'NftIcon',
  //   href: '/bridge/nfts',
  // },
  {
    label: t('Garden Management'),
    icon: 'LiquidityIcon',
    href: '/liquidity/manage',
  },
  // {
  //   label: t('$BASE Mines'),
  //   icon: 'GroupsIcon',
  //   href: '/mining',
  // },
  {
    label: t('Garden Harvest'),
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: t('Buy $BASE'),
    icon: 'NftIcon',
    href: '/swap',
  },
  {
    label: t('Party area'),
    icon: 'NftIcon',
    href: '/community',
  },
  {
    label: t('defido.com'),
    icon: 'HomeIcon',
    href: 'https://defido.com',
  },
  {
    label: t('Not our game'),
    icon: 'BurnIcon',
    href: 'https://sunflower-land.com',
  },
  // {
  //   label: t('RewardPools'),
  //   icon: 'PoolIcon',
  //   href: '/pools',
  // },
  // {
  //   label: t('Buy Back & Burn'),
  //   icon: 'BurnIcon',
  //   href: '/buyback/stats',
  // },
  {
    label: t('More'),
    icon: 'MoreIcon',
    items: [
      {
        label: t('Community'),
        href: 'https://defido.chat',
      },
      {
        label: t('Reddit Community Garden'),
        href: 'https://reddit.com/r/defidocoin',
      },
      {
        label: t('Support'),
        href: 'https://defido.chat',
      },
      // {
      //   label: t('Github'),
      //   href: 'https://github.com/crosschainbridge',
      // },
      // {
      //   label: t('Docs'),
      //   href: 'https://docs.crosschainbridge.org',
      // },
      // {
      //   label: t('Terms and Conditions'),
      //   href: 'https://drive.google.com/file/d/1k76mPutQmkW0erIFRObHY-B3zBS7PsU9/view',
      // },
      // {
      //   label: t('crosschainbridge.org'),
      //   href: 'https://crosschainbridge.org',
      // },
    ],
  },
]

export default config

import { PoolConfig } from '../types'
import { ChainId } from '../chainId'
import getTokens, { getTokensList } from '../tokens'
import { asyncExtendedPools } from '../asyncExtendedPools'

const contractAddress = {
  [ChainId.ETHEREUM]: '0x0BDC1f983bC82B8F6F6BCcbF9810A9cdC1FE455f',
  [ChainId.BSC]: '0x0BDC1f983bC82B8F6F6BCcbF9810A9cdC1FE455f',
  [ChainId.POLYGON]: '0x0BDC1f983bC82B8F6F6BCcbF9810A9cdC1FE455f',
  [ChainId.AVALANCHE]: '0xbAb537b7AE2Fcb00eeA7e91Fa4782EEbaD3B6d10',
  [ChainId.FANTOM]: '0x6eBC0D4Ae955218195E6D016Fb9D4358Ee34d1F9',
}

let pools

function initialPools() {
  const tokens = getTokens()

  pools = [
    {
      rewardPoolId: 1,
      stakingToken: tokens.bridge,
      earningToken: tokens.usdc,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    // {
    //   rewardPoolId: 2,
    //   stakingToken: tokens.bridge,
    //   earningToken: tokens.usdt,
    //   contractAddress,
    //   harvest: true,
    //   sortOrder: 999,
    //   tokenPerBlock: '1',
    //   isFinished: false,
    // },
    {
      rewardPoolId: 0,
      stakingToken: tokens.bridge,
      earningToken: tokens.bridge,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    // {
    //   rewardPoolId: 3,
    //   stakingToken: tokens.bridge,
    //   earningToken: tokens.weth,
    //   contractAddress,
    //   harvest: true,
    //   sortOrder: 999,
    //   tokenPerBlock: '1',
    //   isFinished: false,
    // },
    // {
    //   rewardPoolId: 16,
    //   stakingToken: tokens.bridge,
    //   earningToken: tokens.daf,
    //   contractAddress,
    //   harvest: true,
    //   sortOrder: 999,
    //   tokenPerBlock: '1',
    //   isFinished: false,
    // },
  ]
}

export default function getPools(): PoolConfig[] {
  if (!pools) {
    initialPools()
  }

  return pools
}

asyncExtendedPools(
  'reward',
  'rewardPoolId',
  ChainId.FANTOM,
  contractAddress,
  getPools,
  getTokensList,
  getTokensList,
  (pool) => pools.push(pool),
).catch((err) => console.error('Dynamic pools', err))

import { PoolConfig } from '../types'
import { ChainId } from '../chainId'
import getTokens, { getTokensList } from '../tokens'
import { asyncExtendedPools } from '../asyncExtendedPools'

const contractAddress = {
  [ChainId.ETHEREUM]: '',
  [ChainId.BSC]: '',
  [ChainId.RINKEBY]: '0x51c0856412f794d0f95997627044D217513ac1Eb',
  [ChainId.BSCTEST]: '0x45A19B609F25fD60a228A0EC698D3b98f09e9c2d',
  [ChainId.LETH]: '0x92417136F219bf18f610AaC849F69c618bD2d381',
  [ChainId.LBSC]: '0x92417136F219bf18f610AaC849F69c618bD2d381',
}

let pools

function initialPools() {
  const tokens = getTokens()

  pools = [
    // {
    //   rewardPoolId: 2,
    //   stakingToken: tokens.bridge,
    //   earningToken: tokens.txl,
    //   contractAddress,
    //   harvest: true,
    //   sortOrder: 999,
    //   tokenPerBlock: '0.01261',
    //   isFinished: false,
    // },
  ]
}

export default function getPools(): PoolConfig[] {
  if (!pools) {
    initialPools()
  }

  return pools
}

asyncExtendedPools(
  'reward',
  'rewardPoolId',
  ChainId.LETH,
  contractAddress,
  getPools,
  getTokensList,
  getTokensList,
  (pool) => pools.push(pool),
).catch((err) => console.error('Dynamic pools', err))

import { Currency } from '@pancakeswap/sdk'
import { tokenToUrl } from 'components/TokenImage'
import { Collection } from 'config/constants/nftCollections'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import React, { useMemo } from 'react'
import { Token } from 'sdk/entities/token'
import styled from 'styled-components'
import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import getTokenLogoURL from '../../utils/getTokenLogoURL'
import Logo from './Logo'

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 50%;
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style,
}: {
  currency?: Currency | Token
  size?: string
  style?: React.CSSProperties
}) {
  const { chainId } = useActiveWeb3React()
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)

  const srcs: string[] = useMemo(() => {
    if (!currency) return []

    const { address } = currency as Token

    if (address) {
      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, tokenToUrl(chainId, address)]
      }

      return [`/images/tokens/${address}.svg`, `/images/tokens/${address}.png`, tokenToUrl(chainId, address)]
    }

    if (currency instanceof Currency) {
      return [`/images/native/${currency.symbol?.toLowerCase()}.jpg`]
    }

    return []
  }, [chainId, currency, uriLocations])

  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
}

export function CollectionLogo({
  collection,
  size = '24px',
  style,
}: {
  collection: Collection
  size?: string
  style?: React.CSSProperties
}) {
  const srcs: string[] = useMemo(() => {
    return [getTokenLogoURL(collection.address)]
  }, [collection])

  return <StyledLogo size={size} srcs={srcs} alt={`${collection.name ?? 'Collection'} logo`} style={style} />
}

import getTokens, { quoteTokens } from '../tokens'
import getLPTokens from '../tokensLP'
import { FarmConfig } from '../types'

export default function getFarms(): FarmConfig[] {
  const lpTokens = getLPTokens()
  const tokens = getTokens()

  return [
    /**
     * by order of release (some may be out of PID order due to multiplier boost)
     */
    {
      farmId: 2,
      lpSymbol: 'USDC-LP',
      lpToken: lpTokens.usdc,
      token: tokens.usdc,
    },
    // {
    //   farmId: 3,
    //   lpSymbol: 'USDT-LP',
    //   lpToken: lpTokens.usdt,
    //   token: tokens.usdt,
    // },
    {
      farmId: 1,
      lpSymbol: 'BRIDGE-LP',
      lpToken: lpTokens.bridge,
      token: tokens.bridge,
    },
    {
      farmId: 0,
      lpSymbol: 'BRIDGE/FTM-LP',
      lpToken: lpTokens.bridgeDex,
      token: tokens.bridge,
      quoteToken: quoteTokens.ftm,
    },
  ]
}

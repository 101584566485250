import { DYNAMIC_TOKENS_URL } from 'config'
import fetch from 'node-fetch'

import { symbolAddressMatch, Token } from 'sdk/entities/token'
import { ChainId } from './chainId'

export type TokenConfig = {
  symbol: string
  address: Record<number, string>
  decimals: Record<ChainId, number>
  projectLink: string
}

const tokens = {
  // txl: {
  //   symbol: 'TXL',
  //   address: {
  //     1: '0x8eEF5a82E6Aa222a60F009ac18c24EE12dBf4b41',
  //     4: '0x99684438D9234A2E25ad1c728B0be920C888c717',
  //     56: '0x1FFD0b47127fdd4097E54521C9E2c7f0D66AafC5',
  //     97: '0x0Cd0E4B8E48554C6fCcA4826E1Ed19bB2001f78c',
  //     137: '0x8eEF5a82E6Aa222a60F009ac18c24EE12dBf4b41',
  //     6661: '0xE7FE736010EC02699bd56BD44dca530CDE8477A7',
  //     6662: '0xe37463863131D848533A263FB4F8f7D1DBFC414e',
  //   },
  //   decimals: {
  //     1: 18,
  //     4: 18,
  //     56: 18,
  //     97: 18,
  //     137: 18,
  //     6661: 18,
  //     6662: 18,
  //   },
  //   projectLink: 'https://tixl.org/',
  // },
  bridge: {
    symbol: 'BRIDGE',
    address: {
      1: '0x92868A5255C628dA08F550a858A802f5351C5223',
      4: '0xbdD503F86EeE2630a7f623277C3E569dbC8e5A74',
      56: '0x92868A5255C628dA08F550a858A802f5351C5223',
      97: '0xDB31E32e551Aae41736cBB917517d6a218e721Db',
      137: '0x92868A5255C628dA08F550a858A802f5351C5223',
      6661: '0x7aeF06FE6D4E9655F336F4cccB369cdB559D6597',
      6662: '0x7aeF06FE6D4E9655F336F4cccB369cdB559D6597',
      [ChainId.AVALANCHE]: '0xC0367f9b1f84Ca8DE127226AC2A994EA4bf1e41b',
      [ChainId.FANTOM]: '0x92868A5255C628dA08F550a858A802f5351C5223',
    },
    decimals: {
      1: 18,
      4: 18,
      56: 18,
      97: 18,
      137: 18,
      6661: 18,
      6662: 18,
      [ChainId.AVALANCHE]: 18,
      [ChainId.FANTOM]: 18,
    },
    projectLink: 'https://crosschainbridge.org/',
  },
  // weth: {
  //   symbol: 'WETH',
  //   address: {
  //     1: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  //     4: '0xc778417E063141139Fce010982780140Aa0cD5Ab',
  //     56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
  //     97: '0x891afc97cd4ae718680a058fc659716712fffacc',
  //     137: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
  //     6661: '0x295020Da312cc27Fe90f4Cb5E692845668b5c445',
  //     6662: '0x295020Da312cc27Fe90f4Cb5E692845668b5c445',
  //     [ChainId.AVALANCHE]: '0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab',
  //     [ChainId.FANTOM]: '0x74b23882a30290451A17c44f4F05243b6b58C76d',
  //   },
  //   decimals: {
  //     1: 18,
  //     4: 18,
  //     56: 18,
  //     97: 18,
  //     137: 18,
  //     6661: 18,
  //     6662: 18,
  //     [ChainId.AVALANCHE]: 18,
  //     [ChainId.FANTOM]: 18,
  //   },
  //   projectLink: 'https://weth.io/',
  // },
  wmatic: {
    symbol: 'BASE',
    address: {
      1: '0x0422b63182798ae9876cae3caBd225d96B284D14',
      4: '0x0422b63182798ae9876cae3caBd225d96B284D14',
      56: '0x0422b63182798ae9876cae3caBd225d96B284D14',
      97: '0x0422b63182798ae9876cae3caBd225d96B284D14',
      137: '0x0422b63182798ae9876cae3caBd225d96B284D14',
      6661: '0x0422b63182798ae9876cae3caBd225d96B284D14',
      6662: '0x0422b63182798ae9876cae3caBd225d96B284D14',
      [ChainId.AVALANCHE]: '0x0422b63182798ae9876cae3caBd225d96B284D14',
      [ChainId.FANTOM]: '0x0422b63182798ae9876cae3caBd225d96B284D14',
    },
    decimals: {
      1: 18,
      4: 18,
      56: 18,
      97: 18,
      137: 18,
      6661: 18,
      6662: 18,
      [ChainId.AVALANCHE]: 18,
      [ChainId.FANTOM]: 18,
    },
    projectLink: 'https://defido.com',
  },
  // wbnb: {
  //   symbol: 'WBNB',
  //   address: {
  //     1: '0xB8c77482e45F1F44dE1745F52C74426C631bDD52',
  //     4: '',
  //     56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //     97: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
  //     137: '',
  //     6661: '',
  //     6662: '',
  //   },
  //   decimals: {
  //     1: 18,
  //     4: 18,
  //     56: 18,
  //     97: 18,
  //     137: 18,
  //     6661: 18,
  //     6662: 18,
  //   },
  //   projectLink: 'https://www.binance.org/',
  // },
  usdc: {
    symbol: 'USDC',
    address: {
      1: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
      4: '0xfC4E710276460b22c2500a724c1433D9E45C2f33',
      56: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      97: '0x36656ba80348C0D1978eF2248eA57C3784140aE3',
      137: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
      6661: '0x2EB1Da43309e571a60289a8Eac0C5B38bC702b0c',
      6662: '0xFc17c036b434E6229DC36EF36a6568240D5461DE',
      [ChainId.AVALANCHE]: '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
      [ChainId.FANTOM]: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    },
    decimals: {
      1: 6,
      4: 18,
      56: 18,
      97: 18,
      137: 6,
      6661: 18,
      6662: 18,
      [ChainId.AVALANCHE]: 6,
      [ChainId.FANTOM]: 6,
    },
    projectLink: 'https://www.centre.io/usdc',
  },
  // usdt: {
  //   symbol: 'USDT',
  //   address: {
  //     1: '0xdac17f958d2ee523a2206206994597c13d831ec7',
  //     4: '0x7d85b5201eB2cC961E4E4f6B90895B555D4C428c',
  //     56: '0x55d398326f99059fF775485246999027B3197955',
  //     97: '0xf9582db316c874b1E50262A32848214f6e6BA629',
  //     137: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
  //     [ChainId.AVALANCHE]: '0xc7198437980c041c805a1edcba50c1ce5db95118',
  //     [ChainId.FANTOM]: '0x049d68029688eabf473097a2fc38ef61633a3c7a',
  //   },
  //   decimals: {
  //     1: 6,
  //     4: 18,
  //     56: 18,
  //     97: 18,
  //     137: 6,
  //     6661: 18,
  //     6662: 18,
  //     [ChainId.AVALANCHE]: 6,
  //     [ChainId.FANTOM]: 6,
  //   },
  //   projectLink: 'https://tether.to/',
  // },
  // link: {
  //   symbol: 'LINK',
  //   address: {
  //     1: '', // '0x514910771af9ca656af840dff83e8264ecf986ca',
  //     4: '0x01327C1F6E403484fc94534e658FF6b759055f37',
  //     56: '', // '0x404460c6a5ede2d891e8297795264fde62adbb75',
  //     97: '0xc54fe24488c6c944480785b0a374De8661406df4',
  //   },
  //   decimals: {
  //     1: 18,
  //     4: 18,
  //     56: 18,
  //     97: 18,
  //   },
  //   projectLink: 'https://chain.link/',
  // },
  // pmon: {
  //   symbol: 'PMON',
  //   address: {
  //     1: '0x1796ae0b0fa4862485106a0de9b654eFE301D0b2',
  //     4: '0xb48b8f352f7806f6cc1aab1ed23a064611e07fc6',
  //     56: '0x1796ae0b0fa4862485106a0de9b654eFE301D0b2',
  //     97: '0xec7eE180b4b9464500b32Eb261d987afD60A7be8',
  //     137: '0x1796ae0b0fa4862485106a0de9b654eFE301D0b2',
  //     6661: '',
  //     6662: '',
  //   },
  //   decimals: {
  //     1: 18,
  //     4: 18,
  //     56: 18,
  //     97: 18,
  //     137: 18,
  //     6661: 18,
  //     6662: 18,
  //   },
  //   projectLink: 'https://polychainmonsters.com',
  // },
  // gfx: {
  //   symbol: 'GFX',
  //   address: {
  //     1: '0x65ad6a2288b2dd23e466226397c8f5d1794e58fc',
  //     4: '',
  //     56: '0x65ad6a2288b2dd23e466226397c8f5d1794e58fc',
  //     97: '',
  //     137: '0x65ad6a2288b2dd23e466226397c8f5d1794e58fc',
  //     6661: '',
  //     6662: '',
  //   },
  //   decimals: {
  //     1: 18,
  //     4: 18,
  //     56: 18,
  //     97: 18,
  //     137: 18,
  //     6661: 18,
  //     6662: 18,
  //   },
  //   projectLink: 'https://www.gamyfi.org/',
  // },
  // bulk: {
  //   symbol: 'BULK',
  //   address: {
  //     1: '0xa143ac515dca260a46c742c7251ef3b268639593',
  //     4: '',
  //     56: '0xa143ac515dca260a46c742c7251ef3b268639593',
  //     97: '',
  //     137: '',
  //     6661: '',
  //     6662: '',
  //   },
  //   decimals: {
  //     1: 18,
  //     4: 18,
  //     56: 18,
  //     97: 18,
  //     137: 18,
  //     6661: 18,
  //     6662: 18,
  //   },
  //   projectLink: '',
  // },
  // mny: {
  //   symbol: 'MNY',
  //   address: {
  //     1: '0xA6F7645ed967FAF708A614a2fcA8D4790138586f',
  //     4: '',
  //     56: '0xA6F7645ed967FAF708A614a2fcA8D4790138586f',
  //     97: '',
  //     137: '', // '0xA6F7645ed967FAF708A614a2fcA8D4790138586f',
  //     6661: '',
  //     6662: '',
  //   },
  //   decimals: {
  //     1: 18,
  //     4: 18,
  //     56: 18,
  //     97: 18,
  //     137: 18,
  //     6661: 18,
  //     6662: 18,
  //   },
  //   projectLink: '',
  // },
  // ulti: {
  //   symbol: 'ULTI',
  //   address: {
  //     1: '',
  //     4: '',
  //     56: '0x42BFE4A3E023f2C90aEBFfbd9B667599Fa38514F',
  //     97: '',
  //     137: '0xa6516f07c5fc7169fca3149b188c37ca617f1d41',
  //     6661: '',
  //     6662: '',
  //   },
  //   decimals: {
  //     1: 18,
  //     4: 18,
  //     56: 18,
  //     97: 18,
  //     137: 18,
  //     6661: 18,
  //     6662: 18,
  //   },
  //   projectLink: 'https://ultiarena.com/',
  // },
  // uno: {
  //   symbol: 'UNO',
  //   address: {
  //     1: '0x474021845c4643113458ea4414bdb7fb74a01a77',
  //     4: '',
  //     56: '0x474021845c4643113458ea4414bdb7fb74a01a77',
  //     97: '',
  //     137: '',
  //     6661: '',
  //     6662: '',
  //   },
  //   decimals: {
  //     1: 18,
  //     4: 18,
  //     56: 18,
  //     97: 18,
  //     137: 18,
  //     6661: 18,
  //     6662: 18,
  //   },
  //   projectLink: 'https://unore.io',
  // },
  // island: {
  //   symbol: 'ISLAND',
  //   address: {
  //     1: '0xA0DC5132c91eA4D94FCf1727c32CC5a303B34CfC',
  //     4: '',
  //     56: '0xA0DC5132c91eA4D94FCf1727c32CC5a303B34CfC',
  //     97: '',
  //     137: '',
  //     6661: '',
  //     6662: '',
  //   },
  //   decimals: {
  //     [ChainId.ETHEREUM]: 9,
  //     [ChainId.BSC]: 9,
  //     [ChainId.RINKEBY]: 9,
  //     [ChainId.POLYGON]: 9,
  //     [ChainId.BSCTEST]: 9,
  //     [ChainId.LETH]: 9,
  //     [ChainId.LBSC]: 9,
  //   },
  //   projectLink: 'https://islanddoges.com/',
  // },
  // dax: {
  //   symbol: 'DAX',
  //   address: {
  //     1: '0x77E9618179820961eE99a988983BC9AB41fF3112',
  //     4: '',
  //     56: '0x2cb34F6a300813da9312b84ab566B2e51cc02921',
  //     97: '',
  //     137: '',
  //     6661: '',
  //     6662: '',
  //   },
  //   decimals: {
  //     [ChainId.ETHEREUM]: 18,
  //     [ChainId.BSC]: 18,
  //     [ChainId.RINKEBY]: 18,
  //     [ChainId.POLYGON]: 18,
  //     [ChainId.BSCTEST]: 18,
  //     [ChainId.LETH]: 18,
  //     [ChainId.LBSC]: 18,
  //   },
  //   projectLink: 'https://www.dragonx.app/',
  // },
  // uct: {
  //   symbol: 'UCT',
  //   address: {
  //     1: '0x6d1dc3928604b00180bb570bdae94b9698d33b79',
  //     4: '',
  //     56: '0x6d1dc3928604b00180bb570bdae94b9698d33b79',
  //     97: '',
  //     137: '',
  //     6661: '',
  //     6662: '',
  //   },
  //   decimals: {
  //     [ChainId.ETHEREUM]: 18,
  //     [ChainId.BSC]: 18,
  //     [ChainId.RINKEBY]: 18,
  //     [ChainId.POLYGON]: 18,
  //     [ChainId.BSCTEST]: 18,
  //     [ChainId.LETH]: 18,
  //     [ChainId.LBSC]: 18,
  //   },
  //   projectLink: 'https://unitedcrowd.com/',
  // },
  // realm: {
  //   symbol: 'REALM',
  //   address: {
  //     1: '0x464FdB8AFFC9bac185A7393fd4298137866DCFB8',
  //     4: '',
  //     56: '0x464FdB8AFFC9bac185A7393fd4298137866DCFB8',
  //     97: '',
  //     137: '',
  //     6661: '',
  //     6662: '',
  //   },
  //   decimals: {
  //     [ChainId.ETHEREUM]: 18,
  //     [ChainId.BSC]: 18,
  //     [ChainId.RINKEBY]: 18,
  //     [ChainId.POLYGON]: 18,
  //     [ChainId.BSCTEST]: 18,
  //     [ChainId.LETH]: 18,
  //     [ChainId.LBSC]: 18,
  //   },
  //   projectLink: 'https://www.realm.art/',
  // },
  // upr: {
  //   symbol: 'UPR',
  //   address: {
  //     1: '0xf720e38f678b29b243f7d53b56acbf5de98f2385',
  //     4: '',
  //     56: '0xf720e38f678b29b243f7d53b56acbf5de98f2385',
  //     97: '',
  //     137: '',
  //     6661: '',
  //     6662: '',
  //   },
  //   decimals: {
  //     [ChainId.ETHEREUM]: 18,
  //     [ChainId.BSC]: 18,
  //     [ChainId.RINKEBY]: 18,
  //     [ChainId.POLYGON]: 18,
  //     [ChainId.BSCTEST]: 18,
  //     [ChainId.LETH]: 18,
  //     [ChainId.LBSC]: 18,
  //   },
  //   projectLink: 'https://upfire.com/',
  // },
  // daf: {
  //   symbol: 'DAF',
  //   address: {
  //     1: '',
  //     4: '',
  //     56: '0x8fb1a59ca2d57b51e5971a85277efe72c4492983',
  //     97: '',
  //     137: '0x2f0e07e881363bb1cdff32971b2f8c87ef8ff432',
  //     6661: '',
  //     6662: '',
  //     [ChainId.AVALANCHE]: '0x9485a1657BCFe5746551554c6Cbaf15327a681DE',
  //     [ChainId.FANTOM]: '0x9aa2bc9580c1c019eb0da7f1cc90997c8f96eef0',
  //   },
  //   decimals: {
  //     [ChainId.ETHEREUM]: 6,
  //     [ChainId.BSC]: 6,
  //     [ChainId.POLYGON]: 6,
  //     [ChainId.AVALANCHE]: 6,
  //     [ChainId.FANTOM]: 6,
  //     [ChainId.RINKEBY]: 6,
  //     [ChainId.BSCTEST]: 6,
  //     [ChainId.LETH]: 6,
  //     [ChainId.LBSC]: 6,
  //   },
  //   projectLink: 'https://www.daftoken.io/',
  // },
  /*
  spaceBattleShip: {
    symbol: 'SBS',
    address: {
      1: '',
      4: '',
      56: '0xE35A3D25B623C6e55bEf588f5B4338FD38911b05',
      97: '',
      137: '0xE35A3D25B623C6e55bEf588f5B4338FD38911b05',
      6661: '',
      6662: '',
    },
    decimals: {
      [ChainId.ETHEREUM]: 9,
      [ChainId.BSC]: 9,
      [ChainId.RINKEBY]: 9,
      [ChainId.POLYGON]: 9,
      [ChainId.BSCTEST]: 9,
      [ChainId.LETH]: 9,
      [ChainId.LBSC]: 9,
    },
    projectLink: '',
  },
  */
  // haka: {
  //   symbol: 'HAKA',
  //   address: {
  //     1: '0xd85ad783cc94bd04196a13dc042a3054a9b52210',
  //     4: '',
  //     56: '0xd85ad783cc94bd04196a13dc042a3054a9b52210',
  //     97: '',
  //     137: '',
  //     6661: '',
  //     6662: '',
  //   },
  //   decimals: {
  //     [ChainId.ETHEREUM]: 18,
  //     [ChainId.BSC]: 18,
  //     [ChainId.RINKEBY]: 18,
  //     [ChainId.POLYGON]: 18,
  //     [ChainId.BSCTEST]: 18,
  //     [ChainId.LETH]: 18,
  //     [ChainId.LBSC]: 18,
  //   },
  //   projectLink: 'https://www.tribeone.io/',
  // },
  // cndl: {
  //   symbol: 'CNDL',
  //   address: {
  //     1: '0xbc138bD20C98186CC0342C8e380953aF0cb48BA8',
  //     4: '',
  //     56: '0xdaBcdBc8eCEbE3b2f59ee7B5FC1a46FEEF50dB5A',
  //     97: '',
  //     137: '0x5423063af146F5abF88Eb490486E6B53FA135eC9',
  //     6661: '',
  //     6662: '',
  //   },
  //   decimals: {
  //     [ChainId.ETHEREUM]: 18,
  //     [ChainId.RINKEBY]: 18,
  //     [ChainId.BSC]: 18,
  //     [ChainId.BSCTEST]: 18,
  //     [ChainId.POLYGON]: 18,
  //     [ChainId.LETH]: 18,
  //     [ChainId.LBSC]: 18,
  //   },
  //   projectLink: 'candlelabs.org',
  // },
  // yop: {
  //   symbol: 'YOP',
  //   address: {
  //     1: '0xae1eaae3f627aaca434127644371b67b18444051',
  //     4: '',
  //     56: '0xae1eaae3f627aaca434127644371b67b18444051',
  //     97: '',
  //     137: '',
  //     6661: '',
  //     6662: '',
  //   },
  //   decimals: {
  //     [ChainId.ETHEREUM]: 8,
  //     [ChainId.BSC]: 8,
  //     [ChainId.RINKEBY]: 8,
  //     [ChainId.POLYGON]: 8,
  //     [ChainId.BSCTEST]: 8,
  //     [ChainId.LETH]: 8,
  //     [ChainId.LBSC]: 8,
  //   },
  //   projectLink: '',
  // },
  // pmlg: {
  //   symbol: 'PMLG',
  //   address: {
  //     1: '0xa14c04DEa16798Aa8F25b1Da583cd5fBBFd6579E',
  //     4: '',
  //     56: '0xa14c04DEa16798Aa8F25b1Da583cd5fBBFd6579E',
  //     97: '',
  //     137: '0xa14c04DEa16798Aa8F25b1Da583cd5fBBFd6579E',
  //     6661: '',
  //     6662: '',
  //   },
  //   decimals: {
  //     [ChainId.ETHEREUM]: 18,
  //     [ChainId.BSC]: 18,
  //     [ChainId.RINKEBY]: 18,
  //     [ChainId.POLYGON]: 18,
  //     [ChainId.BSCTEST]: 18,
  //     [ChainId.LETH]: 18,
  //     [ChainId.LBSC]: 18,
  //   },
  //   projectLink: '',
  // },
  // xgc: {
  //   symbol: 'XGC',
  //   address: {
  //     1: '0x89d3c0249307Ae570A316030764D12f53BB191FD',
  //     4: '',
  //     56: '0xd37EF7df703B6f8eeC56CD4E082Bb3e899e41F2B',
  //     97: '',
  //     137: '',
  //     6661: '',
  //     6662: '',
  //   },
  //   decimals: {
  //     [ChainId.ETHEREUM]: 14,
  //     [ChainId.BSC]: 14,
  //     [ChainId.RINKEBY]: 14,
  //     [ChainId.POLYGON]: 14,
  //     [ChainId.BSCTEST]: 14,
  //     [ChainId.LETH]: 14,
  //     [ChainId.LBSC]: 14,
  //   },
  //   projectLink: 'https://xiglutecoin.io/',
  // },
  // zoo: {
  //   symbol: 'ZOO',
  //   address: {
  //     1: '0x57A28626985E5e45f8fA425C84f10b571B400D8D',
  //     4: '',
  //     56: '0x7fFC1243232da3Ac001994208E2002816b57c669',
  //     97: '',
  //     137: '',
  //     6661: '',
  //     6662: '',
  //   },
  //   decimals: {
  //     [ChainId.ETHEREUM]: 18,
  //     [ChainId.BSC]: 18,
  //     [ChainId.RINKEBY]: 18,
  //     [ChainId.POLYGON]: 18,
  //     [ChainId.BSCTEST]: 18,
  //     [ChainId.LETH]: 18,
  //     [ChainId.LBSC]: 18,
  //   },
  //   projectLink: 'https://cryptozoo.co/',
  // },
  // ccs: {
  //   symbol: 'CCS',
  //   address: {
  //     1: '0xf79deaBc1406a3AD07c70877fBaEb90777B77E68',
  //     4: '',
  //     56: '0x3e3b357061103dc040759ac7dceeaba9901043ad',
  //     97: '',
  //     137: '',
  //     6661: '',
  //     6662: '',
  //   },
  //   decimals: {
  //     [ChainId.ETHEREUM]: 0,
  //     [ChainId.BSC]: 0,
  //     [ChainId.RINKEBY]: 0,
  //     [ChainId.POLYGON]: 0,
  //     [ChainId.BSCTEST]: 0,
  //     [ChainId.LETH]: 0,
  //     [ChainId.LBSC]: 0,
  //   },
  //   projectLink: 'https://cloutcontracts.net/',
  // },
}

// used for Farms to support double token pools.
export const quoteTokens = {
  wbnb: {
    symbol: 'WBNB',
    address: {
      1: '0xB8c77482e45F1F44dE1745F52C74426C631bDD52',
      4: '',
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      97: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
      137: '',
      6661: '',
      6662: '',
    },
    decimals: {
      1: 18,
      4: 18,
      56: 18,
      97: 18,
      137: 18,
      6661: 18,
      6662: 18,
    },
    projectLink: 'https://www.binance.org/',
  },
  weth: {
    symbol: 'WETH',
    address: {
      1: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
      4: '0xc778417E063141139Fce010982780140Aa0cD5Ab',
      56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
      97: '0x891afc97cd4ae718680a058fc659716712fffacc',
      137: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
      6661: '0x295020Da312cc27Fe90f4Cb5E692845668b5c445',
      6662: '0x295020Da312cc27Fe90f4Cb5E692845668b5c445',
      [ChainId.AVALANCHE]: '0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab',
      [ChainId.FANTOM]: '0x74b23882a30290451A17c44f4F05243b6b58C76d',
    },
    decimals: {
      1: 18,
      4: 18,
      56: 18,
      97: 18,
      137: 18,
      6661: 18,
      6662: 18,
      [ChainId.AVALANCHE]: 18,
      [ChainId.FANTOM]: 18,
    },
    projectLink: 'https://weth.io/',
  },
  avax: {
    symbol: 'AVAX',
    address: {
      1: '',
      4: '',
      56: '',
      97: '',
      137: '',
      6661: '',
      6662: '',
      [ChainId.AVALANCHE]: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756ab2',
      [ChainId.FANTOM]: '',
    },
    decimals: {
      1: 18,
      4: 18,
      56: 18,
      97: 18,
      137: 18,
      6661: 18,
      6662: 18,
      [ChainId.AVALANCHE]: 18,
      [ChainId.FANTOM]: 18,
    },
    projectLink: 'https://www.avax.network/',
  },
  ftm: {
    symbol: 'FTM',
    address: {
      1: '',
      4: '',
      56: '',
      97: '',
      137: '',
      6661: '',
      6662: '',
      [ChainId.AVALANCHE]: '',
      [ChainId.FANTOM]: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756ft2',
    },
    decimals: {
      1: 18,
      4: 18,
      56: 18,
      97: 18,
      137: 18,
      6661: 18,
      6662: 18,
      [ChainId.AVALANCHE]: 18,
      [ChainId.FANTOM]: 18,
    },
    projectLink: 'https://fantom.foundation/',
  },
}

// list of tokens on mainnets that have custom path mapping configured for burns
export const tokenSymbolsWhitelistedForBurn: { [chainId: number]: string[] } = {
  1: ['BRIDGE', 'USDC', 'PMON', 'GFX', 'BULK', 'MNY', 'UNO', 'ISLAND', 'UTC', 'REALM', 'UPR'],
  4: ['BRIDGE', 'USDC', 'PMON', 'GFX', 'BULK', 'MNY', 'UNO', 'ISLAND', 'UTC', 'REALM', 'UPR', 'DAX', 'WETH', 'USDT'],
  56: ['PMON', 'MNY', 'ULTI', 'USDT', 'WETH', 'UTC', 'REALM', 'DAF'],
  97: [
    'MNY',
    'ULTI',
    'USDT',
    'WETH',
    'UTC',
    'REALM',
    'DAF',
    'USDC',
    'BRIDGE',
    'GFX',
    'BULK',
    'UNO',
    'ISLAND',
    'DAX',
    'UPR',
  ],
  137: ['BRIDGE', 'USDC', 'PMON', 'USDT', 'WETH'],
  6661: [''],
  6662: [''],
}

// used to filter only the tokens we configured in the tokens.ts because not
// all tokens have custom path mapping configured in the smart contract
export const getWhitelistedTokensForBurn = (chainID: number, tokensArray: Token[]) => {
  const tokenSymbolsPerNetwork: string[] = tokenSymbolsWhitelistedForBurn[chainID]
  return tokensArray.filter((eachToken) => tokenSymbolsPerNetwork.includes(eachToken.symbol))
}

// sourceTokens returns a map of source chain adress to token that are available (configurable)
export function sourceTokens(sourceChainId: number, targetChainId: number) {
  const map: { [address: string]: Token } = {}

  Object.keys(tokens).forEach((key) => {
    const token = tokens[key]

    // filter for defined target chains
    if (targetChainId && !token.address[targetChainId]) return
    if (targetChainId && token.address[targetChainId].length === 0) return

    // filter for source chain
    if (token.address[sourceChainId]) {
      if (typeof token.decimals[sourceChainId] === 'undefined') {
        throw new Error(`Could not find decimals for chain ID ${sourceChainId}`)
      }
      map[token.address[sourceChainId]] = new Token(
        sourceChainId,
        token.address[sourceChainId],
        token.decimals[sourceChainId],
        token.symbol,
        token.name,
      )
    }
  })

  return map
}

export function getTokenAddress(token: Token, chainId: ChainId): string | undefined {
  if (!token) return undefined

  let result

  Object.keys(tokens).forEach((key) => {
    const tokenConfig = tokens[key]

    if (token.symbol !== tokenConfig.symbol) return

    result = tokenConfig.address[chainId]
  })

  return result
}

export function getToken(chainId: ChainId, symbolOrAddress: string): Token | undefined {
  if (!chainId) return undefined

  let tokenResult
  Object.keys(tokens).forEach((key) => {
    const token = tokens[key]

    if (symbolAddressMatch(symbolOrAddress, token.address[chainId], token.symbol)) {
      if (typeof token.decimals[chainId] === 'undefined') {
        // eslint-disable-next-line no-console
        console.log(`Could not find decimals for chain ID ${chainId} for token ${token.symbol}`)
      } else if (token.address[chainId]?.length > 0) {
        tokenResult = new Token(chainId, token.address[chainId], token.decimals[chainId], token.symbol, token.name)
      }
    }
  })

  return tokenResult
}

export default function getTokens() {
  return tokens
}

export function getTokensList(): TokenConfig[] {
  return Object.keys(tokens).map((key) => tokens[key])
}

// Extend tokenlist from endpoint
async function extendTokensAsync() {
  if (!process.env.REACT_APP_DYNAMIC_TOKENS_URL && process.env.NODE_ENV === 'production') {
    console.error('No URL set to load tokens dynamically')
    return
  }

  const url = `${DYNAMIC_TOKENS_URL}/tokens.json`
  const res = await fetch(url)

  if (!res.ok) {
    console.error(`Fetch ${url} failed with ${res.status}: ${await res.text()}`)
    return
  }

  const json = await res.json()

  if (!json.tokens) {
    console.error('Dynamic tokens returned empty response (no tokens)')
    return
  }

  Object.keys(json.tokens).forEach((item) => {
    const token = json.tokens[item]
    const nextKey = token.symbol.toLowerCase()

    // Check that the symbol does not exist
    const symbolMap = Object.keys(tokens).map((key) => tokens[key].symbol)

    if (symbolMap.some((symbol) => symbol === token.symbol)) {
      console.error(`Cannot extend tokens with ${token.symbol}: already exists`)
      return
    }

    if (!tokens[nextKey]) {
      console.info(`Add dynamic token: ${token.symbol}`)

      tokens[nextKey] = token
    }
  })
}

// Calling this once per module load
extendTokensAsync().catch((err) => console.error('Dynamic tokens', err))

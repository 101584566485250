import React, { useCallback, useMemo } from 'react'
import { InjectedModalProps, Modal, Text, useModal } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import Button from 'theme/components/Button/Button'
import { Row } from 'components/Layout/Row'
import { useDispatch, useSelector } from 'react-redux'
import { addClaimLink, ClaimLinkInfo } from 'state/claims/actions'
import { AppState } from 'state'
import { useLiquidityManagerActionHandlers } from '../../../state/liquidity/hooks'

export function useSaveClaimModal(claimType: 'erc20' | 'erc721', isWithdrawal = false) {
  const bridgeERC20 = useSelector<AppState, AppState['bridgeERC20']>((state) => state.bridgeERC20)
  const bridgeERC721 = useSelector<AppState, AppState['bridgeERC721']>((state) => state.bridgeERC721)
  const liquidity = useSelector<AppState, AppState['liquidity']>((state) => state.liquidity)

  const info = useMemo<ClaimLinkInfo | undefined>(() => {
    if (claimType === 'erc20') {
      if (isWithdrawal) {
        return {
          type: 'erc20',
          depositHash: liquidity.depositHash,
          sourceChainId: liquidity.sourceChainId,
          targetChainId: liquidity.targetChainId,
          currencyId: liquidity.currencyId,
          typedAmount: liquidity.typedAmount,
        }
      }
      return {
        type: 'erc20',
        depositHash: bridgeERC20.depositHash,
        sourceChainId: bridgeERC20.sourceChainId,
        targetChainId: bridgeERC20.targetChainId,
        currencyId: bridgeERC20.currencyId,
        typedAmount: bridgeERC20.typedAmount,
      }
    }

    if (claimType === 'erc721') {
      return {
        type: 'erc721',
        depositHash: bridgeERC721.depositHash,
        sourceChainId: bridgeERC721.sourceChainId,
        targetChainId: bridgeERC721.targetChainId,
        currencyId: bridgeERC721.collectionId,
        typedAmount: bridgeERC721.typedToken,
      }
    }

    return undefined
  }, [claimType, bridgeERC20, bridgeERC721, liquidity, isWithdrawal])

  const [onPresentSaveClaimModal] = useModal(
    <SaveClaimModal info={info} isWithdrawal={isWithdrawal} />,
    true,
    true,
    'saveClaimModal',
  )

  return { onPresentSaveClaimModal }
}

interface SaveClaimModalProps {
  info: ClaimLinkInfo
  isWithdrawal?: boolean
}

export default function SaveClaimModal({ onDismiss, info, isWithdrawal }: InjectedModalProps & SaveClaimModalProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { onReset } = useLiquidityManagerActionHandlers()
  const onResetView = useCallback(() => {
    dispatch(addClaimLink({ info }))
    // reset liquidity redux state so the UI is ready for next withdrawal
    if (isWithdrawal) {
      onReset()
      if (window) {
        window.location?.reload()
      }
    }

    onDismiss()
  }, [dispatch, onDismiss, info, isWithdrawal, onReset])

  return (
    <Modal title={t('Save claim link for later')} onDismiss={onDismiss}>
      <Row width="436px">
        <div>
          <Text>Fellow community gardener, your bridge has not finished, you can save it for later.</Text>
          <Text mt="3">You can always check the transaction on your deposit chain later.</Text>
          {isWithdrawal && <Text mt="3">Check your wallet for the history.</Text>}
          <Row justify="center">
            <Button mt="3" onClick={onResetView}>
              Come back to this bridge later.
            </Button>
          </Row>
        </div>
      </Row>
    </Modal>
  )
}

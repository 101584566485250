import React from 'react'
import { Svg, SvgProps } from '@pancakeswap/uikit'

interface LogoProps extends SvgProps {
  isDark: boolean
}

const Logo: React.FC<LogoProps> = () => {
  return (
    <img src="/images/dfclogo.png" alt="Defido Coin Logo" />
  )
}

export default React.memo(Logo, (prev, next) => prev.isDark === next.isDark)

import { DYNAMIC_TOKENS_URL } from 'config'
import { ChainId } from './chainId'
import { TokenConfig } from './tokens'

const lpTokens = {
  bridgeDex: {
    symbol: 'BRIDGE-DEX-LP',
    address: {
      [ChainId.ETHEREUM]: '0x8C341D1B28fb4Aef7387ecf7ce253FaE43298c23',
      [ChainId.BSC]: '0x7bcAb836B13B550Bee1E91e939eDC30A85157Aeb',
      [ChainId.POLYGON]: '0x26b7c77e9a0ddb1fe467f6922462a735e1cccdcb',
      [ChainId.AVALANCHE]: '0xb2d0a24dadd43b6cca4548d017e39f3b6a748910',
      [ChainId.FANTOM]: '0x304adaa75de4fa1cac5cdb514164449f17cc0366',
      [ChainId.RINKEBY]: '',
      [ChainId.BSCTEST]: '',
      [ChainId.LETH]: '',
      [ChainId.LBSC]: '',
    },
    decimals: {
      [ChainId.ETHEREUM]: 18,
      [ChainId.BSC]: 18,
      [ChainId.POLYGON]: 18,
      [ChainId.AVALANCHE]: 18,
      [ChainId.FANTOM]: 18,
      [ChainId.RINKEBY]: 18,
      [ChainId.BSCTEST]: 18,
      [ChainId.LETH]: 18,
      [ChainId.LBSC]: 18,
    },
  },
  bridge: {
    symbol: 'BRIDGE-LP',
    address: {
      [ChainId.ETHEREUM]: '0xF4051AC5723F5517B265a51B444C98e0A0F78F1c',
      [ChainId.BSC]: '0xF4051AC5723F5517B265a51B444C98e0A0F78F1c',
      [ChainId.POLYGON]: '0xF4051AC5723F5517B265a51B444C98e0A0F78F1c',
      [ChainId.RINKEBY]: '',
      [ChainId.BSCTEST]: '',
      [ChainId.LETH]: '',
      [ChainId.LBSC]: '',
      [ChainId.AVALANCHE]: '0x3A65FafF6417f611fA43eaA0286C587653A7541B',
      [ChainId.FANTOM]: '0x4cbA09ddb08291F2D6C7a994cd152FD571d81C2F',
    },
    decimals: {
      [ChainId.ETHEREUM]: 18,
      [ChainId.BSC]: 18,
      [ChainId.RINKEBY]: 18,
      [ChainId.BSCTEST]: 18,
      [ChainId.LETH]: 18,
      [ChainId.LBSC]: 18,
      [ChainId.POLYGON]: 18,
      [ChainId.AVALANCHE]: 18,
      [ChainId.FANTOM]: 18,
    },
  },
  txl: {
    symbol: 'TXL-LP',
    address: {
      [ChainId.ETHEREUM]: '0x4E381D7a341D0e60FcDEF09299B1C2EBCC7c5cc0',
      [ChainId.BSC]: '0x4E381D7a341D0e60FcDEF09299B1C2EBCC7c5cc0',
      [ChainId.POLYGON]: '0x4e381d7a341d0e60fcdef09299b1c2ebcc7c5cc0',
      [ChainId.RINKEBY]: '0x7ba11BcA03Bb8A31dA10faBB3a212EaF04A8A2d0',
      [ChainId.BSCTEST]: '0xE09EA4A074525cFBd11894eb0A7079b6E990c7C2',
      [ChainId.LETH]: '0x013F96E25453a1A8A62F687129a7B29B14b43b07',
      [ChainId.LBSC]: '0x013F96E25453a1A8A62F687129a7B29B14b43b07',
    },
    decimals: {
      [ChainId.ETHEREUM]: 18,
      [ChainId.BSC]: 18,
      [ChainId.RINKEBY]: 18,
      [ChainId.BSCTEST]: 18,
      [ChainId.LETH]: 18,
      [ChainId.LBSC]: 18,
      [ChainId.POLYGON]: 18,
    },
  },
  usdc: {
    symbol: 'USDC-LP',
    address: {
      [ChainId.ETHEREUM]: '0xC6c82017E53917652459438EcE2ec2583321A222',
      [ChainId.BSC]: '0xC6c82017E53917652459438EcE2ec2583321A222',
      [ChainId.POLYGON]: '0xC6c82017E53917652459438EcE2ec2583321A222',
      [ChainId.RINKEBY]: '0xCcfb4275602ccA7C3c7961F90D1Ce445593F8A2E',
      [ChainId.BSCTEST]: '0x879196E3151Be1616Dd94836Cc325E73638aaBC8',
      [ChainId.LETH]: '0xB03Fb53677786d40D80681b95f9f2E7164231d20',
      [ChainId.LBSC]: '0xB03Fb53677786d40D80681b95f9f2E7164231d20',
      [ChainId.AVALANCHE]: '0xCA80D34e046754208939E25BeD911199966026cC',
      [ChainId.FANTOM]: '0xfF3438c80ebcba7b7Cb105cC182Edb0420c8908d',
    },
    decimals: {
      [ChainId.ETHEREUM]: 18,
      [ChainId.BSC]: 18,
      [ChainId.POLYGON]: 18,
      [ChainId.RINKEBY]: 18,
      [ChainId.BSCTEST]: 18,
      [ChainId.LETH]: 18,
      [ChainId.LBSC]: 18,
      [ChainId.AVALANCHE]: 18,
      [ChainId.FANTOM]: 18,
    },
  },
  weth: {
    symbol: 'WETH-LP',
    address: {
      [ChainId.ETHEREUM]: '0x5c89468CC28dF0d3e421D707A392690c02f6cb00',
      [ChainId.BSC]: '0x6F6033dF6a2ef92A62295e1d305F386641de9135',
      [ChainId.POLYGON]: '0xeddf548A3C6379FDa4171F4f4bd34cDd9b199F9e',
      [ChainId.RINKEBY]: '',
      [ChainId.BSCTEST]: '',
      [ChainId.LETH]: '',
      [ChainId.LBSC]: '',
      [ChainId.AVALANCHE]: '0x8B2C97B02C397B4302bd2FDfF95B665323c21bDA',
      [ChainId.FANTOM]: '0xFd32DA21CfBC2d95fEcf681bF910e943b3577916',
    },
    decimals: {
      [ChainId.ETHEREUM]: 18,
      [ChainId.BSC]: 18,
      [ChainId.POLYGON]: 18,
      [ChainId.RINKEBY]: 18,
      [ChainId.BSCTEST]: 18,
      [ChainId.LETH]: 18,
      [ChainId.LBSC]: 18,
      [ChainId.AVALANCHE]: 18,
      [ChainId.FANTOM]: 18,
    },
  },
  usdt: {
    symbol: 'USDT-LP',
    address: {
      [ChainId.ETHEREUM]: '0x290cdEFA713a110527f3a9Aee000cF52DeA18d1B',
      [ChainId.BSC]: '0x886aeB3423545f9fE53d5A91b92Aa8D032C39A73',
      [ChainId.POLYGON]: '0x886aeB3423545f9fE53d5A91b92Aa8D032C39A73',
      [ChainId.RINKEBY]: '0x74aA867E08225a5406B0560Dd6b5865a3Db5200C',
      [ChainId.BSCTEST]: '0x4F52a5C2465e3B7FFC615585a271a8B0BeCc4248',
      [ChainId.LETH]: '',
      [ChainId.LBSC]: '',
      [ChainId.AVALANCHE]: '0x2a8374B05b60521a79ff0CA01B7b52b9A75d2615',
      [ChainId.FANTOM]: '0x1Ecab02f55dce9EA7d09C08B9eeAd1486853F86F',
    },
    decimals: {
      [ChainId.ETHEREUM]: 6,
      [ChainId.BSC]: 18,
      [ChainId.POLYGON]: 6,
      [ChainId.RINKEBY]: 18,
      [ChainId.BSCTEST]: 18,
      [ChainId.LETH]: 18,
      [ChainId.LBSC]: 18,
      [ChainId.AVALANCHE]: 18,
      [ChainId.FANTOM]: 18,
    },
  },
  gfx: {
    symbol: 'GFX-LP',
    address: {
      [ChainId.ETHEREUM]: '0x886aeB3423545f9fE53d5A91b92Aa8D032C39A73',
      [ChainId.BSC]: '0xD2A957b56d7D3d0D1fc82624A6f6864b1119A81f',
      [ChainId.POLYGON]: '0xD2A957b56d7D3d0D1fc82624A6f6864b1119A81f',
      [ChainId.RINKEBY]: '',
      [ChainId.BSCTEST]: '',
      [ChainId.LETH]: '',
      [ChainId.LBSC]: '',
    },
    decimals: {
      [ChainId.ETHEREUM]: 18,
      [ChainId.BSC]: 18,
      [ChainId.RINKEBY]: 18,
      [ChainId.POLYGON]: 18,
      [ChainId.BSCTEST]: 18,
      [ChainId.LETH]: 18,
      [ChainId.LBSC]: 18,
    },
  },
  pmon: {
    symbol: 'PMON-LP',
    address: {
      [ChainId.ETHEREUM]: '0xD2A957b56d7D3d0D1fc82624A6f6864b1119A81f',
      [ChainId.BSC]: '0x22bce38C62fE4D58139dbaF04d1BEa158CB16982',
      [ChainId.POLYGON]: '0xefD574415E2117A04E96096a5E4594bbc7D00c88',
      [ChainId.RINKEBY]: '',
      [ChainId.BSCTEST]: '',
      [ChainId.LETH]: '',
      [ChainId.LBSC]: '',
    },
    decimals: {
      [ChainId.ETHEREUM]: 18,
      [ChainId.BSC]: 18,
      [ChainId.RINKEBY]: 18,
      [ChainId.POLYGON]: 18,
      [ChainId.BSCTEST]: 18,
      [ChainId.LETH]: 18,
      [ChainId.LBSC]: 18,
    },
  },
  bulk: {
    symbol: 'BULK-LP',
    address: {
      [ChainId.ETHEREUM]: '0xefd574415e2117a04e96096a5e4594bbc7d00c88',
      [ChainId.BSC]: '0x6d854e305ff00c43192224829bc577b852ec6d22',
      [ChainId.POLYGON]: '',
      [ChainId.RINKEBY]: '',
      [ChainId.BSCTEST]: '',
      [ChainId.LETH]: '',
      [ChainId.LBSC]: '',
    },
    decimals: {
      [ChainId.ETHEREUM]: 18,
      [ChainId.BSC]: 18,
      [ChainId.RINKEBY]: 18,
      [ChainId.POLYGON]: 18,
      [ChainId.BSCTEST]: 18,
      [ChainId.LETH]: 18,
      [ChainId.LBSC]: 18,
    },
  },
  mny: {
    symbol: 'MNY-LP',
    address: {
      [ChainId.ETHEREUM]: '0xbBa11C0ac5E8CA522d803DE903D0bD9B21Be7474',
      [ChainId.BSC]: '0xeddf548A3C6379FDa4171F4f4bd34cDd9b199F9e',
      [ChainId.POLYGON]: '',
      [ChainId.RINKEBY]: '',
      [ChainId.BSCTEST]: '',
      [ChainId.LETH]: '',
      [ChainId.LBSC]: '',
    },
    decimals: {
      [ChainId.ETHEREUM]: 18,
      [ChainId.BSC]: 18,
      [ChainId.RINKEBY]: 18,
      [ChainId.POLYGON]: 18,
      [ChainId.BSCTEST]: 18,
      [ChainId.LETH]: 18,
      [ChainId.LBSC]: 18,
    },
  },
  ulti: {
    symbol: 'ULTI-LP',
    address: {
      [ChainId.ETHEREUM]: '',
      [ChainId.BSC]: '0x290cdEFA713a110527f3a9Aee000cF52DeA18d1B',
      [ChainId.POLYGON]: '0x6D854e305ff00c43192224829bC577b852Ec6D22',
      [ChainId.RINKEBY]: '',
      [ChainId.BSCTEST]: '',
      [ChainId.LETH]: '',
      [ChainId.LBSC]: '',
    },
    decimals: {
      [ChainId.ETHEREUM]: 18,
      [ChainId.BSC]: 18,
      [ChainId.RINKEBY]: 18,
      [ChainId.POLYGON]: 18,
      [ChainId.BSCTEST]: 18,
      [ChainId.LETH]: 18,
      [ChainId.LBSC]: 18,
    },
  },
  uno: {
    symbol: 'UNO-LP',
    address: {
      [ChainId.ETHEREUM]: '0x22bce38C62fE4D58139dbaF04d1BEa158CB16982',
      [ChainId.BSC]: '0xcFdf7AF3F68aeA8E6Bb88d788Fbe8b8DB44f624A',
      [ChainId.POLYGON]: '',
      [ChainId.RINKEBY]: '',
      [ChainId.BSCTEST]: '',
      [ChainId.LETH]: '',
      [ChainId.LBSC]: '',
    },
    decimals: {
      [ChainId.ETHEREUM]: 18,
      [ChainId.BSC]: 18,
      [ChainId.RINKEBY]: 18,
      [ChainId.POLYGON]: 18,
      [ChainId.BSCTEST]: 18,
      [ChainId.LETH]: 18,
      [ChainId.LBSC]: 18,
    },
  },
  island: {
    symbol: 'ISLAND-LP',
    address: {
      [ChainId.ETHEREUM]: '0x6D854e305ff00c43192224829bC577b852Ec6D22',
      [ChainId.BSC]: '0xE044616C26467809DF64749bB1E771081586Aa86',
      [ChainId.POLYGON]: '',
      [ChainId.RINKEBY]: '',
      [ChainId.BSCTEST]: '',
      [ChainId.LETH]: '',
      [ChainId.LBSC]: '',
    },
    decimals: {
      [ChainId.ETHEREUM]: 18,
      [ChainId.BSC]: 18,
      [ChainId.RINKEBY]: 18,
      [ChainId.POLYGON]: 18,
      [ChainId.BSCTEST]: 18,
      [ChainId.LETH]: 18,
      [ChainId.LBSC]: 18,
    },
  },
  dax: {
    symbol: 'DAX-LP',
    address: {
      [ChainId.ETHEREUM]: '0x7Cb61A48e7BE69D7921bd2Da5EccAb3b6103418A',
      [ChainId.BSC]: '0x06B51e18D6752747aa01d9Ce7C330D5eB88B6Cf5',
      [ChainId.POLYGON]: '',
      [ChainId.RINKEBY]: '',
      [ChainId.BSCTEST]: '',
      [ChainId.LETH]: '',
      [ChainId.LBSC]: '',
    },
    decimals: {
      [ChainId.ETHEREUM]: 18,
      [ChainId.BSC]: 18,
      [ChainId.RINKEBY]: 18,
      [ChainId.POLYGON]: 18,
      [ChainId.BSCTEST]: 18,
      [ChainId.LETH]: 18,
      [ChainId.LBSC]: 18,
    },
  },
  realm: {
    symbol: 'REALM-LP',
    address: {
      [ChainId.ETHEREUM]: '0xD73657da510B739A66a02a48d555825Ab0B28087',
      [ChainId.BSC]: '0x39369D0aa180dAf4856F3543431d3caef9d240Ed',
      [ChainId.POLYGON]: '',
      [ChainId.RINKEBY]: '',
      [ChainId.BSCTEST]: '',
      [ChainId.LETH]: '',
      [ChainId.LBSC]: '',
    },
    decimals: {
      [ChainId.ETHEREUM]: 18,
      [ChainId.BSC]: 18,
      [ChainId.RINKEBY]: 18,
      [ChainId.POLYGON]: 18,
      [ChainId.BSCTEST]: 18,
      [ChainId.LETH]: 18,
      [ChainId.LBSC]: 18,
    },
  },
  uct: {
    symbol: 'UCT-LP',
    address: {
      [ChainId.ETHEREUM]: '0xeddf548A3C6379FDa4171F4f4bd34cDd9b199F9e',
      [ChainId.BSC]: '0x0F4888e8036407b129A215251A99cC6bdF0D7bF6',
      [ChainId.POLYGON]: '',
      [ChainId.RINKEBY]: '',
      [ChainId.BSCTEST]: '',
      [ChainId.LETH]: '',
      [ChainId.LBSC]: '',
    },
    decimals: {
      [ChainId.ETHEREUM]: 18,
      [ChainId.BSC]: 18,
      [ChainId.RINKEBY]: 18,
      [ChainId.POLYGON]: 18,
      [ChainId.BSCTEST]: 18,
      [ChainId.LETH]: 18,
      [ChainId.LBSC]: 18,
    },
  },
  upr: {
    symbol: 'UPR-LP',
    address: {
      [ChainId.ETHEREUM]: '0xF0b208E66739AE2bd212769Da450F72f71A65b7a',
      [ChainId.BSC]: '0x06d96a00d67dbDd608CB70eE5943B15CcbAFC4d5',
      [ChainId.POLYGON]: '',
      [ChainId.RINKEBY]: '',
      [ChainId.BSCTEST]: '',
      [ChainId.LETH]: '',
      [ChainId.LBSC]: '',
    },
    decimals: {
      [ChainId.ETHEREUM]: 18,
      [ChainId.BSC]: 18,
      [ChainId.RINKEBY]: 18,
      [ChainId.POLYGON]: 18,
      [ChainId.BSCTEST]: 18,
      [ChainId.LETH]: 18,
      [ChainId.LBSC]: 18,
    },
  },
  daf: {
    symbol: 'DAF-LP',
    address: {
      [ChainId.ETHEREUM]: '',
      [ChainId.BSC]: '0xDF3AC0901B41f8EA33d6fb09700166504494ED75',
      [ChainId.POLYGON]: '0x5c89468CC28dF0d3e421D707A392690c02f6cb00',
      [ChainId.AVALANCHE]: '0xae157A7AcD57Ccc2a3f21F3F4BB2CC82856B073B',
      [ChainId.FANTOM]: '0x436fA3f060a5312ef19B39Fe5894674779f2F9A2',
      [ChainId.RINKEBY]: '',
      [ChainId.BSCTEST]: '',
      [ChainId.LETH]: '',
      [ChainId.LBSC]: '',
    },
    decimals: {
      [ChainId.ETHEREUM]: 18,
      [ChainId.BSC]: 18,
      [ChainId.POLYGON]: 18,
      [ChainId.AVALANCHE]: 18,
      [ChainId.FANTOM]: 18,
      [ChainId.RINKEBY]: 18,
      [ChainId.BSCTEST]: 18,
      [ChainId.LETH]: 18,
      [ChainId.LBSC]: 18,
    },
  },
  cndl: {
    symbol: 'CNDL-LP',
    address: {
      [ChainId.ETHEREUM]: '0xE044616C26467809DF64749bB1E771081586Aa86',
      [ChainId.BSC]: '0x2a60db02B2D6Fa5ddfED5069143Be32a7a59Fd47',
      [ChainId.POLYGON]: '0xD252025E9331E3324aE1D59Ead6fE8bf2d3d6E39',
      [ChainId.RINKEBY]: '',
      [ChainId.BSCTEST]: '',
      [ChainId.LETH]: '',
      [ChainId.LBSC]: '',
    },
    decimals: {
      [ChainId.ETHEREUM]: 18,
      [ChainId.BSC]: 18,
      [ChainId.RINKEBY]: 18,
      [ChainId.POLYGON]: 18,
      [ChainId.BSCTEST]: 18,
      [ChainId.LETH]: 18,
      [ChainId.LBSC]: 18,
    },
  },
  haka: {
    symbol: 'HAKA-LP',
    address: {
      [ChainId.ETHEREUM]: '0xEd6d43Cd4987C861aD9e47F157C72Be5fde43954',
      [ChainId.BSC]: '0x5c89468CC28dF0d3e421D707A392690c02f6cb00',
      [ChainId.POLYGON]: '',
      [ChainId.RINKEBY]: '',
      [ChainId.BSCTEST]: '',
      [ChainId.LETH]: '',
      [ChainId.LBSC]: '',
    },
    decimals: {
      [ChainId.ETHEREUM]: 18,
      [ChainId.BSC]: 18,
      [ChainId.RINKEBY]: 18,
      [ChainId.POLYGON]: 18,
      [ChainId.BSCTEST]: 18,
      [ChainId.LETH]: 18,
      [ChainId.LBSC]: 18,
    },
  },
  /*
  spaceBattleShip: {
    symbol: 'SBS-LP',
    address: {
      [ChainId.ETHEREUM]: '',
      [ChainId.BSC]: '0xdAfAFe08f663B6bAB71E08F3E66D0aeef7496238',
      [ChainId.POLYGON]: '0xE044616C26467809DF64749bB1E771081586Aa86',
      [ChainId.RINKEBY]: '',
      [ChainId.BSCTEST]: '',
      [ChainId.LETH]: '',
      [ChainId.LBSC]: '',
    },
    decimals: {
      [ChainId.ETHEREUM]: 18,
      [ChainId.BSC]: 18,
      [ChainId.RINKEBY]: 18,
      [ChainId.POLYGON]: 18,
      [ChainId.BSCTEST]: 18,
      [ChainId.LETH]: 18,
      [ChainId.LBSC]: 18,
    },
  },
   */
  pmlg: {
    symbol: 'PMLG-LP',
    address: {
      1: '0x06B51e18D6752747aa01d9Ce7C330D5eB88B6Cf5',
      4: '',
      56: '0x05A19cB307A9c667B2Bc96E60F3758760A3B6650',
      97: '',
      137: '0xdAfAFe08f663B6bAB71E08F3E66D0aeef7496238',
      6661: '',
      6662: '',
    },
    decimals: {
      [ChainId.ETHEREUM]: 18,
      [ChainId.BSC]: 18,
      [ChainId.RINKEBY]: 18,
      [ChainId.POLYGON]: 18,
      [ChainId.BSCTEST]: 18,
      [ChainId.LETH]: 18,
      [ChainId.LBSC]: 18,
    },
  },
}

export default function getLPTokens() {
  return lpTokens
}

export function getLPTokensList(): TokenConfig[] {
  return Object.keys(lpTokens).map((key) => lpTokens[key])
}

export const bridgeDexName = {
  [ChainId.ETHEREUM]: 'Uni',
  [ChainId.BSC]: 'Pancake',
  [ChainId.POLYGON]: 'Sushi',
  [ChainId.AVALANCHE]: 'Joe',
  [ChainId.FANTOM]: 'Spooky',
  [ChainId.RINKEBY]: 'Uni',
  [ChainId.BSCTEST]: 'Pancake',
}

// Extend LP tokenlist from endpoint
async function extendTokensAsync() {
  if (!process.env.REACT_APP_DYNAMIC_TOKENS_URL && process.env.NODE_ENV === 'production') {
    console.error('No URL set to load tokensLP dynamically')
    return
  }

  const url = `${DYNAMIC_TOKENS_URL}/lpTokens.json`
  const res = await fetch(url)

  if (!res.ok) {
    console.error(`Fetch ${url} failed with ${res.status}: ${await res.text()}`)
    return
  }

  const json = await res.json()

  if (!json.tokens) {
    console.error('Dynamic LP tokens returned empty response (no tokens)')
    return
  }

  Object.keys(json.tokens).forEach((item) => {
    const token = json.tokens[item]
    const nextKey = token.symbol.toLowerCase()

    // Check that the symbol does not exist
    const symbolMap = Object.keys(lpTokens).map((key) => lpTokens[key].symbol)

    if (symbolMap.some((symbol) => symbol === token.symbol)) {
      console.error(`Cannot extend lpToken with ${token.symbol}: already exists`)
      return
    }

    if (!lpTokens[nextKey]) {
      console.info(`Add dynamic LP token: ${token.symbol}`)

      lpTokens[nextKey] = token
    }
  })
}

// Calling this once per module load
extendTokensAsync().catch((err) => console.error('Dynamic LP tokens', err))

import BigNumber from 'bignumber.js'
import fetch from 'node-fetch'
import { getBlocksPerYear, getBridgePerYear } from 'config'
import { ChainId } from '../config/constants/chainId'
import { Pool, StakingPool } from '../state/types'
import { getAddress } from './addressHelpers'
import getAddresses from './getAddresses'
import { getBalanceNumber } from './formatBalance'
import { getNetworkNameByChainId } from '../config/constants/networks'
import getTokens from '../config/constants/tokens'

/**
 * Get the APR value in %
 * @param stakingTokenPrice Token price in the same quote currency
 * @param rewardTokenPrice Token price in the same quote currency
 * @param totalStaked Total amount of stakingToken in the pool
 * @param tokenPerBlock Amount of new BRIDGE allocated to the pool for each new block
 * @param chainId Chain ID to check the APR for
 * @returns Null if the APR is NaN or infinite.
 */
export const getPoolApr = (
  stakingTokenPrice: number,
  rewardTokenPrice: number,
  totalStaked: number,
  tokenPerBlock: number,
  chainId: ChainId,
): number => {
  const totalRewardPricePerYear = new BigNumber(rewardTokenPrice).times(tokenPerBlock).times(getBlocksPerYear(chainId))
  const totalStakingTokenInPool = new BigNumber(stakingTokenPrice).times(totalStaked)
  const apr = totalRewardPricePerYear.div(totalStakingTokenInPool).times(100)
  return apr.isNaN() || !apr.isFinite() ? null : apr.toNumber()
}

/**
 * Get farm APR value in %
 * @param poolWeight allocationPoint / totalAllocationPoint
 * @param bridgePriceUsd BRIDGE price in USD
 * @param poolLiquidityUsd Total pool liquidity in USD
 * @param chainId Chain ID to get the APR for
 * @returns
 */
export const getFarmApr = (
  poolWeight: BigNumber,
  bridgePriceUsd: BigNumber,
  poolLiquidityUsd: BigNumber,
  chainId: ChainId,
): { bridgeRewardsApr: number } => {
  if (!chainId) return { bridgeRewardsApr: 0 }
  if (poolLiquidityUsd.eq(0)) return { bridgeRewardsApr: 0 }

  const yearlyBridgeRewardAllocation = getBridgePerYear(chainId).times(poolWeight)
  const bridgeRewardsApr = yearlyBridgeRewardAllocation.times(bridgePriceUsd).div(poolLiquidityUsd).times(100)
  let bridgeRewardsAprAsNumber = null
  if (!bridgeRewardsApr.isNaN() && bridgeRewardsApr.isFinite()) {
    bridgeRewardsAprAsNumber = bridgeRewardsApr.toNumber()
  }
  return { bridgeRewardsApr: bridgeRewardsAprAsNumber }
}

export const fetchAprForLiquidityMiningPoolList = async (pools: StakingPool[], chainId?: ChainId) => {
  const network = getNetworkNameByChainId(chainId)

  try {
    const stakingContractAddress = getAddresses(chainId).BridgeV2LiquidityMiningPools
    const requestBody = pools
      .map((pool) => ({
        stakingContractAddress,
        network,
        rewardTokenAddress: getAddress(pool.earningToken.address, chainId),
        stakedTokenAddress: getAddress(pool.stakingToken.address, chainId),
        priceDefiningStakedTokenAddress: getAddress(pool.earningToken.address, chainId),
        stakedTokenAmount: getBalanceNumber(pool.totalStaked, pool.earningToken.decimals[chainId]),
      }))
      .filter((reqBodyEl) => reqBodyEl.stakedTokenAmount && reqBodyEl.rewardTokenAddress?.length > 1)

    if (requestBody.length === 0) {
      return {}
    }

    const res = await fetch('https://cross-chain-bridge-prices.herokuapp.com/currentAprs', {
      method: 'post',
      body: JSON.stringify({ tokens: requestBody }),
      headers: { 'Content-Type': 'application/json' },
    })

    if (!res.ok) {
      const err = new Error(`request failed with ${res.status}: ${await res.text()}`)
      console.error(err)
      return err
    }

    return res.json()
  } catch (error) {
    console.error(error)
    return error
  }
}

export const fetchAprForRewardPoolList = async (pools: Pool[], chainId?: ChainId) => {
  const tokens = getTokens()
  const network = getNetworkNameByChainId(chainId)

  try {
    const stakingContractAddress = getAddresses(chainId).BridgeV2RewardPools
    const requestBody = pools
      .map((pool) => ({
        stakingContractAddress,
        network,
        rewardTokenAddress: getAddress(pool.earningToken.address, chainId),
        stakedTokenAddress: getAddress(tokens.bridge.address, chainId),
        stakedTokenAmount: getBalanceNumber(pool.totalStaked, pool.stakingToken.decimals[chainId]),
      }))
      .filter((reqBodyEl) => reqBodyEl.stakedTokenAmount && reqBodyEl.rewardTokenAddress?.length > 1)

    if (requestBody.length === 0) {
      return {}
    }

    const res = await fetch('https://cross-chain-bridge-prices.herokuapp.com/currentAprs', {
      method: 'post',
      body: JSON.stringify({ tokens: requestBody }),
      headers: { 'Content-Type': 'application/json' },
    })

    if (!res.ok) {
      const err = new Error(`request failed with ${res.status}: ${await res.text()}`)
      console.error(err)
      return err
    }

    return res.json()
  } catch (error) {
    console.error(error)
    return error
  }
}

export default null

import { ethers } from 'ethers'
import { simpleRpcProvider } from 'utils/providers'
import { getRewardPoolsConfig, getStakingPoolsConfig } from 'config/constants'

// Addresses
import { getAddress, getBridgeChefAddress, getMulticallAddress } from 'utils/addressHelpers'

// ABI
import erc20Abi from 'config/abi/erc20.json'
import erc721Abi from 'config/abi/erc721.json'
import liquidityMiningAbi from 'config/abi/liquidityMiningPools.json'
import rewardPoolsABI from 'config/abi/rewardPools.json'
import bridgeChefABI from 'config/abi/bridgeChef.json'
import MultiCallAbi from 'config/abi/Multicall.json'
import { ChainId } from '../config/constants/chainId'

const getContract = (abi: any, address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  const signerOrProvider = signer ?? simpleRpcProvider
  return new ethers.Contract(address, abi, signerOrProvider)
}

export const getErc20Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(erc20Abi, address, signer)
}
export const getErc721Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(erc721Abi, address, signer)
}
export const getMulticallContract = (chainId: ChainId, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(MultiCallAbi, getMulticallAddress(chainId), signer)
}

export const getStakingPoolsContract = (
  id: number,
  chainId: ChainId,
  signer?: ethers.Signer | ethers.providers.Provider,
) => {
  const config = getStakingPoolsConfig(chainId).find((pool) => pool.stakingPoolId === id)
  return getContract(liquidityMiningAbi, getAddress(config.contractAddress, chainId), signer)
}

export const getRewardPoolsContract = (
  id: number,
  chainId: ChainId,
  signer?: ethers.Signer | ethers.providers.Provider,
) => {
  const config = getRewardPoolsConfig(chainId).find((pool) => pool.rewardPoolId === id)
  return getContract(rewardPoolsABI, getAddress(config.contractAddress, chainId), signer)
}

export const getBridgeChefContract = (chainId: ChainId, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bridgeChefABI, getBridgeChefAddress(chainId), signer)
}

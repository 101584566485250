import { ChainId } from './chainId'
import liquidityMiningPoolsAvalanche from './avalanche/liquidityMiningPools'
import liquidityMiningPoolsBsc from './bsc/liquidityMiningPools'
import liquidityMiningPoolsBscTest from './bsctest/liquidityMiningPools'
import liquidityMiningPoolsDevEnv from './devenv/liquidityMiningPools'
import liquidityMiningPoolsEthereum from './ethereum/liquidityMiningPools'
import liquidityMiningPoolsFantom from './fantom/liquidityMiningPools'
import liquidityMiningPoolsPolygon from './polygon/liquidityMiningPools'
import liquidityMiningPoolsRinkeby from './rinkeby/liquidityMiningPools'

const getStakingPools = (chainId: ChainId) => {
  switch (chainId) {
    // Dev Environment
    case ChainId.LETH:
    case ChainId.LBSC:
      return liquidityMiningPoolsDevEnv()

    // Testnet
    case ChainId.RINKEBY:
      return liquidityMiningPoolsRinkeby()
    case ChainId.BSCTEST:
      return liquidityMiningPoolsBscTest()

    // Production
    case ChainId.ETHEREUM:
      return liquidityMiningPoolsEthereum()
    case ChainId.BSC:
      return liquidityMiningPoolsBsc()
    case ChainId.POLYGON:
      return liquidityMiningPoolsPolygon()
    case ChainId.AVALANCHE:
      return liquidityMiningPoolsAvalanche()
    case ChainId.FANTOM:
      return liquidityMiningPoolsFantom()

    default:
      return []
  }
}

export default getStakingPools

import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ArrowForwardIcon, CloseIcon } from '@pancakeswap/uikit'
import styled from 'styled-components'
import format from 'date-fns/format'
import { networks } from 'config/constants/networks'
import Text from 'theme/components/Text/Text'
import { LightCard, LightGreyCard } from 'components/Card'
import { Row, RowBetween } from 'components/Layout/Row'
import useSavedClaims from 'state/claims/useSavedClaims'
import { removeClaimLink } from 'state/claims/actions'
import { LinkExternal } from 'theme/components/Link'
import { getToken } from 'config/constants/tokens'
import useParsedQueryString from 'hooks/useParsedQueryString'
import { NETWORK_ICON } from '../../../config/constants/networkIcons'

const InlineText = styled(Text)`
  display: inline-block;
`

export const NetworkIconImg = styled.img`
  background-color: #fee500;
  border-color: #fee500;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  height: 32px;
  left: 0;
  width: 32px;
  margin-top: 6px;
`

const ClickableIcon = styled.div`
  display: inline-flex;
  width: 22px;
  height: 22px;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const CloseButton = styled.div`
  position: absolute;
  top: -11px;
  right: -11px;
  background: lightgray;
  border-radius: 50%;
`

const AlignRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`

const LabelButton = styled.button`
  border: 0;
  background-color: transparent;
  color: #0051ff;
  cursor: pointer;
  display: inline-block;
`

export default function ClaimList() {
  const queryString = useParsedQueryString()
  const [claims] = useSavedClaims('erc20')
  const [editMode, setEditMode] = useState(false)

  if (!claims || !claims.length) return null

  return (
    <Row width="100%" maxWidth="436px" marginTop="2em">
      <LightCard>
        <Text fontSize="14px" mb="3" bold>
          Saved claims
          <LabelButton onClick={() => setEditMode(!editMode)}>Edit</LabelButton>
        </Text>
        <div>
          {claims.map((claim) => {
            if (!claim.depositHash) return null

            const isActive = queryString.release && (queryString.release as string).includes(claim.depositHash)
            const releaseLink = `/bridge/tokens?release=${claim.sourceChainId},${claim.targetChainId},${claim.depositHash}`
            const networkSource = networks.find((net) => net.chainId === claim.sourceChainId)
            const networkTarget = networks.find((net) => net.chainId === claim.targetChainId)
            const token = getToken(claim.targetChainId, claim.currencyId)

            return (
              <LightGreyCard key={claim.depositHash} mb="3" highlight={isActive} padding="0.5rem 1rem">
                <RowBetween>
                  <InlineText>
                    <NetworkIconImg src={NETWORK_ICON[claim.sourceChainId]} alt={networkSource?.name} />
                    <InlineText mx="1" verticalAlign="super">
                      <ArrowForwardIcon width="14px" color="primary" />
                    </InlineText>
                    <NetworkIconImg src={NETWORK_ICON[claim.targetChainId]} alt={networkTarget?.name} />
                  </InlineText>
                  <AlignRight>
                    <LinkExternal href={releaseLink} color="primary" fontSize="14px">
                      {claim.typedAmount} {token?.symbol}
                    </LinkExternal>
                    <Text fontSize="12px" color="textSubtle">
                      {claim.createdAt ? format(new Date(claim.createdAt), 'dd.MM.yyyy - HH:mm') : ''}
                    </Text>
                  </AlignRight>
                </RowBetween>

                <ClaimListRemoveButton depositHash={claim.depositHash} editMode={editMode} />
              </LightGreyCard>
            )
          })}
        </div>
      </LightCard>
    </Row>
  )
}

export function ClaimListRemoveButton({ depositHash, editMode }) {
  const dispatch = useDispatch()
  const queryString = useParsedQueryString()
  const hasActive = !!queryString.release

  const onRemoveSelf = useCallback(() => {
    if (window.confirm('Are you sure? This action cannot be undone.')) {
      dispatch(removeClaimLink({ depositHash }))
    }
  }, [dispatch, depositHash])

  if (!hasActive && !editMode) return null

  return (
    <CloseButton>
      <ClickableIcon onClick={onRemoveSelf}>
        <CloseIcon />
      </ClickableIcon>
    </CloseButton>
  )
}

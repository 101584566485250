import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'Defido Bridge | Not a utility how dare you suggest that',
  description:
    'The Defido Coin $BASE, not a utility coin, allows gardeners to stake, bridge & harvest $BASE & other tokens, using the crosschainbridge.org infrastructure. An audited, highly secure German innovation. Germans know how to farm.',
  image: 'https://garden.defido.com/images/logo.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  switch (path) {
    case '/':
      return {
        title: `${t('Defido Community Garden')} | ${t('Connecting Gardeners')}`,
      }
    case '/home':
      return {
        title: `${t('Defido Community Garden Bridge')} | ${t('Connecting Gardeners')}`,
      }
    case '/bridge/tokens':
      return {
        title: `${t('Bridge $BASE')} | ${t('Defido Coin $BASE')}`,
      }
    case '/bridge/nfts':
      return {
        title: `${t('Bridge NFTs')} | ${t('Defido Coin $BASE')}`,
      }
    case '/liquidity/manage':
      return {
        title: `${t('Add/Manage Liquidity')} | ${t('Defido Coin $BASE')}`,
      }
    case '/mining':
      return {
        title: `${t('Liquidity Mining')} | ${t('Defido Coin $BASE')}`,
      }
    case '/farms':
      return {
        title: `${t('Farms')} | ${t('Defido Coin $BASE')}`,
      }
    case '/pools':
      return {
        title: `${t('Reward Pools')} | ${t('Defido Coin $BASE')}`,
      }
    default:
      return null
  }
}

import React, { useContext, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { Currency } from '@pancakeswap/sdk'
import { Button, InfoIcon, Text } from '@pancakeswap/uikit'
import { AutoColumn } from 'components/Layout/Column'
import { AutoRow, RowBetween } from 'components/Layout/Row'
import { SwapCallbackError } from 'components/styleds'
import { TokenAmount } from 'sdk/entities/tokenAmount'
import CircleLoader from 'components/Loader/CircleLoader'
import { WarningLabelRow } from '../../../components/AddressInputPanel'
import { ChainId } from '../../../config/constants/chainId'
import { CheckItem } from '../../../components/ActiveBridgeInfo/ActiveBridgeInfo'
import { GreyCard } from '../../../components/Card'

export default function BridgeModalFooter({
  currency,
  fees,
  onConfirm,
  swapErrorMessage,
  disabledConfirm,
  targetChainId,
  isWithdrawalToOtherNetwork,
}: {
  currency: Currency
  fees: TokenAmount | undefined
  onConfirm: () => void
  swapErrorMessage: string | undefined
  disabledConfirm?: boolean
  isWithdrawalToOtherNetwork: boolean
  targetChainId?: number
}) {
  const theme = useContext(ThemeContext)
  // useBridgingFees(sourceChainId)
  const [isAccepted, setIsAccepted] = useState(false)

  // show during bridgings to Ethereum
  const isBridgingToEth =
    targetChainId === ChainId.ETHEREUM && (isWithdrawalToOtherNetwork === undefined || !isWithdrawalToOtherNetwork)

  // show only during withdrawals to another network && to Ethereum
  const isWithdrawalToAnotherNetworkAndToEth = isWithdrawalToOtherNetwork && targetChainId === ChainId.ETHEREUM

  const handleAccept = () => setIsAccepted(!isAccepted)

  return (
    <div style={{ paddingBottom: isWithdrawalToAnotherNetworkAndToEth || isBridgingToEth ? '30px' : '0' }}>
      {isWithdrawalToOtherNetwork ? (
        <></>
      ) : (
        <AutoColumn>
          <RowBetween align="center">
            <Text fontWeight={400} fontSize="14" color={theme.colors.text}>
              Protocol incentive fees
            </Text>
            <Text
              fontWeight={500}
              fontSize="14"
              color={theme.colors.text}
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                textAlign: 'right',
                paddingLeft: '10px',
              }}
            >
              {fees === undefined ? <CircleLoader /> : `${fees.toFixed(6)} ${currency.symbol}`}
            </Text>
          </RowBetween>
        </AutoColumn>
      )}

      {(isBridgingToEth || isWithdrawalToAnotherNetworkAndToEth) && (
        <AutoRow margin="0 auto" maxWidth={320}>
          <RowBetween align="center">
            <WarningLabelRow>
              <InfoIcon mr="10px" color="#FF0000" />
              <Text fontSize="14px">
                ⚠️ Garden member! ⚠️
                <br /> {isWithdrawalToOtherNetwork ? 'Withdrawal to another network' : 'Bridging'} consists of 2
                transactions:
                <br />
                <strong>MAKE SURE YOU HAVE ETH ON THE ETHEREUM CHAIN TO PAY THE GAS</strong>
              </Text>
            </WarningLabelRow>
          </RowBetween>
        </AutoRow>
      )}

      {(isBridgingToEth || isWithdrawalToAnotherNetworkAndToEth) && (
        <AutoRow margin="0 auto" maxWidth={320}>
          <RowBetween align="center">
            <WarningLabelRow>
              <InfoIcon mr="10px" color="#FF0000" />
              <Text fontSize="14px">
              Do not bridge if you have under $200USD in ETH on Ethereum...{' '}
              </Text>
            </WarningLabelRow>
          </RowBetween>
        </AutoRow>
      )}

      {(isBridgingToEth || isWithdrawalToAnotherNetworkAndToEth) && (
        <AutoRow maxWidth={320} margin="10px auto">
          <GreyCard
            display="flex"
            onClick={handleAccept}
            style={{ backgroundColor: isAccepted && '#fee500', cursor: 'pointer' }}
          >
            <CheckItem iconMarginBottom="0" checkboxWidth="20px" checked={isAccepted} />
            <Text
              ml="10px"
              textAlign="center"
              fontSize="13px"
              color={isAccepted ? '#000' : '#000'}
            >
              <strong>I have 0.2ETH+ on Ethereum for Gas</strong>
            </Text>
          </GreyCard>
        </AutoRow>
      )}

      <AutoRow>
        <Button
          variant="primary"
          width="100%"
          onClick={onConfirm}
          disabled={disabledConfirm || ((isBridgingToEth || isWithdrawalToAnotherNetworkAndToEth) && !isAccepted)}
          style={{ margin: '10px 0 0 0' }}
          id="confirm-swap-or-send"
        >
          {isWithdrawalToOtherNetwork ? 'Confirm Withdrawal' : 'Confirm Bridging'}
        </Button>

        {swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
      </AutoRow>
    </div>
  )
}

import { StakingPoolConfig } from '../types'
import { ChainId } from '../chainId'
import getLPTokens, { getLPTokensList } from '../tokensLP'
import getTokens, { getTokensList } from '../tokens'
import { asyncExtendedPools } from '../asyncExtendedPools'

const contractAddress = {
  [ChainId.ETHEREUM]: '',
  [ChainId.BSC]: '',
  [ChainId.RINKEBY]: '0xC3d706B53543718CCFAB5926f7f6c4a8d34Cf003',
  [ChainId.BSCTEST]: '0xB7d14a8400Cd5CD05130DC2BC72970B060F7233f',
  [ChainId.LETH]: '0xF93AE55237221c074d45cafa9A895c396cf5F52D',
  [ChainId.LBSC]: '0xF93AE55237221c074d45cafa9A895c396cf5F52D',
}

let pools

function initialPools() {
  const lpTokens = getLPTokens()
  const tokens = getTokens()

  pools = [
    {
      stakingPoolId: 1,
      stakingToken: lpTokens.usdc,
      earningToken: tokens.usdc,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '0.01261',
      isFinished: false,
    },
    // {
    //   stakingPoolId: 2,
    //   stakingToken: lpTokens.txl,
    //   earningToken: tokens.txl,
    //   contractAddress,
    //   harvest: true,
    //   tokenPerBlock: '0.434027',
    //   sortOrder: 999,
    //   isFinished: false,
    // },
  ]
}

export default function getPools(): StakingPoolConfig[] {
  if (!pools) {
    initialPools()
  }

  return pools
}

asyncExtendedPools(
  'liquidityMining',
  'stakingPoolId',
  ChainId.LETH,
  contractAddress,
  getPools,
  getLPTokensList,
  getTokensList,
  (pool) => pools.push(pool),
).catch((err) => console.error('Dynamic pools', err))
